import { Component, Input, inject } from "@angular/core";
import { SourcesService } from "../../../sources.service";
import { FailoverSource } from "../../../../../models/shared";

@Component({
    selector: "zx-source-sources-action-buttons",
    templateUrl: "./zx-source-sources-action-buttons.component.html"
})
export class ZxSourceSourcesActionButtonsComponent {
    @Input() source: FailoverSource;
    loadingDetails = false;
    private ss = inject(SourcesService);

    async toggleComponentSourceLock(failoverSource, lock: boolean) {
        this.loadingDetails = true;
        await this.ss.toggleComponentSourceLock(failoverSource, lock);
        this.loadingDetails = false;
    }
}
