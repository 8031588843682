<div class="form-group">
    <zx-forms-field-label translateKeyLabel="TAGS" [tooltipsConfigs]="tooltipsConfigs" [inputId]="inputId"></zx-forms-field-label>
    <ng-select
        [labelForId]="inputId"
        class="form-control"
        [ngClass]="{ 'is-invalid': isInvalid }"
        [items]="tags"
        [(ngModel)]="selectedTagsIds"
        bindValue="id"
        bindLabel="name"
        placeholder="{{ 'SELECT' | translate }} {{ 'TAGS' | translate }}"
        [loading]="isLoading"
        [disabled]="isDisabled"
        [multiple]="true"
        [clearable]="true"
        [hideSelected]="true"
        [closeOnSelect]="false"
        [clearSearchOnAdd]="true"
        (change)="notifyControlOnChange(selectedTagsIds); updateMostRecentTagsIds()"
        (blur)="notifyControlOnTouch($event)"
    >
    </ng-select>
    <zx-forms-field-errors *ngIf="isInvalid" [errors]="ngControl.control.errors"></zx-forms-field-errors>
</div>
