import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { isEmpty, map } from "lodash";
import { BroadcastersService } from "../../broadcasters/broadcasters.service";
import { ChannelTargetBroadcasterPreference } from "@zixi/models";

type Settings = {
    specificBroadcasterOnly?: boolean;
    allowAnyBroadcaster?: boolean;
};

@Component({
    selector: "zx-target-broadcaster-selection",
    templateUrl: "./target-broadcaster-selection.component.html",
    styleUrls: ["./target-broadcaster-selection.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class TargetBroadcasterSelectionComponent implements OnInit {
    @Input() clusterId: number;
    @Input() settings: Settings = {};
    @Input() disable = false;
    @Input() targetBroadcasterId: number | ChannelTargetBroadcasterPreference | null;
    @Output() targetBroadcasterIdChange = new EventEmitter<number>();

    targetBXLoading = false;
    targetBXs;
    targetBXsBase = [
        { name: this.translate.instant("ANY"), id: ChannelTargetBroadcasterPreference.AnyBroadcaster },
        {
            name: this.translate.instant("PREFER_PRIMARY_BROADCASTERS"),
            id: ChannelTargetBroadcasterPreference.PreferPrimary
        },
        {
            name: this.translate.instant("PRIMARY_BROADCASTERS_ONLY"),
            id: ChannelTargetBroadcasterPreference.PrimaryOnly
        },
        { name: this.translate.instant("BACKUP_BROADCASTERS_ONLY"), id: ChannelTargetBroadcasterPreference.BackupOnly },
        {
            name: this.translate.instant("PREFER_BACKUP_BROADCSTER"),
            id: ChannelTargetBroadcasterPreference.PreferBackup
        }
    ];

    constructor(private translate: TranslateService, private broadcastersService: BroadcastersService) {}

    async ngOnInit() {
        await this.getTargetBroadcasters(this.clusterId);
        this.targetBroadcasterIdChange.emit(this.targetBroadcasterId);
    }

    async getTargetBroadcasters(id: number) {
        this.targetBXLoading = true;

        if (!isEmpty(this.settings)) {
            if (this.settings.allowAnyBroadcaster === false) {
                this.targetBXsBase = this.targetBXsBase.filter(
                    item => item.id !== ChannelTargetBroadcasterPreference.AnyBroadcaster
                );
            }
        }

        const broadcasters = await this.broadcastersService.refreshBroadcasters(id, true).toPromise();
        if (broadcasters && broadcasters.length > 0) {
            this.targetBXs = (this.settings.specificBroadcasterOnly ? [] : this.targetBXsBase).concat(
                map(broadcasters, broadcaster => {
                    return {
                        id: broadcaster.id,
                        name: broadcaster.name,
                        type: "broadcaster",
                        generalStatus: broadcaster.generalStatus
                    };
                })
            );
        }
        this.targetBXLoading = false;
    }

    onTargetBroadcasterChange($event) {
        this.targetBroadcasterId = $event.id;
        this.targetBroadcasterIdChange.emit(this.targetBroadcasterId);
    }
}
