<div class="form-group">
    <zx-forms-field-label [translateKeyLabel]="translateKey.label" [tooltipsConfigs]="tooltipConfigsForRendering" [inputId]="inputId"></zx-forms-field-label>
    <ng-select
        class="form-control"
        [ngClass]="{ 'is-invalid': isInvalid }"
        [labelForId]="inputId"
        [(ngModel)]="selectedValue"
        [items]="items"
        placeholder="{{ (translateKey.placeholder | translate) || (translateKey.label | translate) }}"
        [bindLabel]="bindLabelToItemProperty"
        [bindValue]="bindValueToItemProperty"
        [disabled]="isDisabled"
        [loading]="isLoading"
        [clearable]="isClearable"
        (change)="notifyControlOnChange(selectedValue)"
        (blur)="notifyControlOnTouch($event)"
    >
    </ng-select>
    <zx-forms-field-errors *ngIf="isInvalid" [errors]="ngControl.control.errors"></zx-forms-field-errors>
</div>
