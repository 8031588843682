import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort, MatSortable, Sort } from "@angular/material/sort";
import { MatSelectChange } from "@angular/material/select";
import { MatTableDataSource } from "@angular/material/table";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { SelectionModel } from "@angular/cdk/collections";
import { ContainerDataDirective } from "./container-data.directive";
import { KeyMap } from "src/app/models/shared";
import { Subject, Subscription, take } from "rxjs";
import { ResizeColumnDirective } from "./resize-column.directive";
import { TranslateService } from "@ngx-translate/core";
import { SharedService } from "src/app/services/shared.service";
import { ExportExcelService } from "src/app/services/export-excel.service";
import * as _ from "lodash";
import { Cluster } from "src/app/pages/clusters/cluster";
import {
    ColumnFilter,
    ColumnFilterType,
    FilterComponent,
    filterFunctions,
    isTableFilter,
    operatorTypes,
    TableFilter
} from "../filter/filter.component";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { Base } from "@zixi/models";
import { TourService } from "ngx-ui-tour-md-menu";
import { TourSteps } from "src/app/constants/tour-steps";
import { MatMenuTrigger } from "@angular/material/menu";
import { MixpanelService } from "src/app/services/mixpanel.service";

export interface ComponentType<> {
    new (...args: unknown[]);
}

export interface TableSchema<R = KeyMap<unknown>, T = unknown, C = ComponentType> {
    columnDef: string;
    header: string;
    visible: boolean;
    sticky?: number;
    stickyToLast?: boolean;
    width?: number;
    align?: string;
    bgValue?: (row: R) => number;
    style?: (row: R) => string;
    expandDisabled?: (row: R) => boolean;
    hideColumnName?: boolean;
    hideFromColumnChooser?: boolean;
    translate?: boolean;
    tooltip?: string;
    columnFilterType?: ColumnFilterType;
    columnFilterValue?: (row: R) => string | number | string[] | number[];
    columnSelectOptions?: string[];
    component?: C;
    assignComponentsInputs?: (compRef: ComponentRef<T>, row: R, searchTerm: string | string[]) => void;
    textValue?: (row: R) => string | number;
    sortBy?: (row: R) => string | number;
    rowIf?: (row: R) => boolean;
    valueToExport?: (row: R) => string | number;
}

class TableListDataSource extends MatTableDataSource<TableObject[]> {
    sortingDataAccessor = function (data: unknown, sortHeaderId: string) {
        // Make string sort case insensitive
        if (typeof data[`${sortHeaderId}SortBy`] === "string") {
            return data[`${sortHeaderId}SortBy`].toLocaleLowerCase();
        }
        return data[`${sortHeaderId}SortBy`];
    };
}

type TableObject = Base & { type?: string; isExpanded?: boolean; disableRow?: boolean };

export type PageInfo = { pageSize: number; pageIndex: number };
@Component({
    selector: "app-table-list[tableName]",
    templateUrl: "./table-list.component.html",
    styleUrls: ["./table-list.component.scss"]
})
export class TableListComponent implements AfterViewInit, OnInit, OnDestroy {
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild("tableContainer", { static: true }) tableContainer: ElementRef;
    @ViewChild("tableFilterContainer", { static: true }) tableFilterContainer: ElementRef;
    @ViewChild("paginatorContainer", { static: true }) paginatorContainer: ElementRef;
    @ViewChildren("tdInjectContainer", { read: ContainerDataDirective })
    tdInjectContainer: QueryList<ContainerDataDirective>;
    @ViewChildren("resizeDirective", { read: ResizeColumnDirective })
    resizeDirective: QueryList<ResizeColumnDirective>;
    @ViewChild("container") container: ElementRef;
    @ViewChild("colTrigger") colTrigger: MatMenuTrigger;
    @ViewChild(FilterComponent) appFilter: FilterComponent;

    createRefComponent() {
        setTimeout(() => {
            const directives = this.tdInjectContainer.toArray();
            for (const directive of directives) {
                const column = directive.appContainerData.column;
                if (!column.component) return;

                const containerRef = directive.viewContainerRef;
                const view = containerRef.get(0);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                let componentRef = (view as unknown as any)?.COMPONENT;

                // clean up UI component if for some reason we lost the reference to it
                if (!componentRef && view) {
                    containerRef.clear();
                }

                // create a new component if we dont have a referecen or it's not in the DOM
                // can happen when the DOM is recreated due to pagination for example
                if (!componentRef || !view) {
                    const injectedComponent = column.component;
                    const factory = this.componentFactoryResolver.resolveComponentFactory<typeof injectedComponent>(
                        column.component
                    );

                    componentRef = containerRef.createComponent<typeof injectedComponent>(factory);
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (componentRef.hostView as unknown as any).COMPONENT = componentRef;
                }

                // create searchTermArray
                let searchTermArray = [];
                if (this.tableData.filter) {
                    const filters = JSON.parse(this.tableData.filter);
                    const filter = filters.find(f => !f.columnHeader);
                    if (filter.value) searchTermArray = [filter.value];
                }

                if (this.childTable) {
                    if (this.hiddenSearchTerm) {
                        searchTermArray = this.hiddenSearchTerm;
                        const filter = [{ value: this.hiddenSearchTerm[0] ?? "" }];
                        const s = JSON.stringify(filter);
                        this.appFilter.applyParentFilter(s);
                    }
                }

                column.assignComponentsInputs(componentRef, directive.appContainerData.row, searchTermArray);
            }
        });
    }

    @Output() rowSelected: EventEmitter<TableObject> = new EventEmitter<TableObject>();
    @Output() dragRowHandler?: EventEmitter<{ dragEvent: DragEvent; row: TableObject }> = new EventEmitter<{
        dragEvent: DragEvent;
        row: TableObject;
    }>();
    @Input() mainColumnDef? = "name";
    @Input() hoverable = true;
    @Input() selectable = true;
    @Input() expanded = false;
    @Input() childTable = false;
    @Input() collapsed = false;
    @Input() hiddenSearchTerm = null;

    @Input() set forceCollapseWithSelection(c: boolean) {
        if (this.childTable) {
            this.toggleCollapseColumnsMode(c, undefined, false);
        } else {
            this.toggleCollapseColumnsMode(c, undefined, true, this.selectedRows);
        }
    }

    private storedTableSchema: TableSchema[];
    private storedUserColumnConfig: string[];
    private firstLoad = true;

    @Input() set tableSchema(value: TableSchema[]) {
        if (!this._tableSchema) {
            this.storedUserColumnConfig = value?.filter(c => c.visible === true).map(c => c.columnDef);
            if (this.showSelectionCheckbox) this.storedUserColumnConfig.unshift("select");
            if (this.showExpandButton) this.storedUserColumnConfig.unshift("expand");
        }

        this._tableSchema = [...this.baseTableSchema, ...value];
    }
    get tableSchema(): TableSchema[] {
        return this._tableSchema;
    }
    @Output() tableSchemaChange: EventEmitter<TableSchema[]> = new EventEmitter<TableSchema[]>();

    @Input() set data(data: TableObject[]) {
        this.inputListData = data;
        this.refreshData();
    }

    get defaultUserColumnConfig(): string[] {
        return this.tableSchema?.filter(c => c.visible === true).map(c => c.columnDef);
    }
    @Input() tableName: string;
    @Input() displayTableName: string;
    @Input() excelReportTitle = "";

    @Output() selectedRowsChange = new EventEmitter<TableObject[]>();
    private ignoreNextSelectedRowsChange = false;
    @Input() set selectedRows(rows: TableObject[]) {
        if (this.ignoreNextSelectedRowsChange && !this.childTable) {
            this.ignoreNextSelectedRowsChange = false;
            return;
        }
        if (this.selectable && rows.length === 0) this.toggleCollapseColumnsMode(this.collapsed ?? false);
        if (this.selectable && rows.length === 1) this.toggleCollapseColumnsMode(true, rows[0]);
        if (!this.selectable || rows.length > 1) this.externalTableSelectionChanged(rows);
    }
    get selectedRows(): TableObject[] {
        return this.tableSelection.selected;
    }

    @Input() showColumnsSelection = true;
    @Input() showSelectionCheckbox = true;
    @Input() showExpandButton = false;
    @Input() showFilter = true;
    @Input() showPagination = true;
    @Input() showReport = true;
    @Input() isColumnSelectionNeeded = true;
    @Input() autoRows? = true;
    @Input() refreshing? = false;
    @Input() showTour? = false;
    @Input() inAccordion? = false;
    @Input() draggable? = false;
    @Input() canDisableCheckboxes? = false; // this flag enables the ability to disable checkboxes. Use disableRow property in the row object to disable a row

    private tourSteps = TourSteps.tableFeatures;

    get userLastColumnConfig(): string[] {
        return (
            this._userLastColumnConfig ??
            localStorage
                .getItem(`${this.tableName}.userColumns`)
                ?.split(",")
                .filter(us => this.tableSchema.find(cs => cs.columnDef === us)) ??
            this.defaultUserColumnConfig
        );
    }

    set userLastColumnConfig(newValue: string[]) {
        this._userLastColumnConfig = newValue;
        if (!this.isCollapseMode) {
            localStorage.setItem(`${this.tableName}.userColumns`, this.userLastColumnConfig.join(","));
        }
    }

    get pageSizePreferenceLocalStorageKey() {
        return `${this.tableName}.pageSizePreference`;
    }

    @Input() infinitePages = false;
    @Output() currentPageInfo = new EventEmitter<PageInfo>();
    @Output() currentPage: EventEmitter<TableObject[]> = new EventEmitter<TableObject[]>();
    @Output() currentSortDirection: EventEmitter<string> = new EventEmitter<string>();

    @HostListener("window:resize", [])
    private onResize() {
        this.calcTableRows();
    }

    columnsToHideFromColumnChooser: string[] = ["select", "all", this.mainColumnDef, "actions", "muted", "expand"];
    tableData: MatTableDataSource<TableObject> = new TableListDataSource();
    tableHeaderSticky = true;
    tableSelection = new SelectionModel<TableObject>(
        true,
        [],
        false,
        (o1, o2) => o1.id === o2.id && o1.type === o2.type
    );
    private _tableSchema: TableSchema[];
    private _userLastColumnConfig;
    private baseTableSchema;
    private inputListData: TableObject[];
    selectedRow: TableObject & { isExpanded?: boolean; disableRow?: boolean };
    private sortedColumnDirection: string[] = [];
    public isCollapseMode: boolean;

    searchTermArray: string[] = [];
    itemsPerPageOptions = [10, 25, 50];
    private calcRowCount: number;
    pageSizeSelectedValue: number | string = "auto";
    noRowsExpanded = true;

    private destroy$ = new Subject<void>();
    private tourSubscription: Subscription;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private translate: TranslateService,
        private sharedService: SharedService,
        private cd: ChangeDetectorRef,
        private excelService: ExportExcelService,
        public tourService: TourService,
        public mixpanelService: MixpanelService
    ) {
        this.baseTableSchema = [
            // Extra column to handle select all
            {
                columnDef: "all",
                header: "All",
                visible: false
            },
            // Select box
            {
                columnDef: "select",
                header: "Select",
                visible: true,
                sticky: 0,
                width: 32
            }
        ];
    }

    ngOnDestroy(): void {
        this.contractAllRows();
        this.destroy$.complete();
        this.tourSubscription.unsubscribe();
    }

    ngOnInit() {
        this.storedTableSchema = JSON.parse(JSON.stringify(this.tableSchema));

        if (!this.showSelectionCheckbox) {
            this.baseTableSchema = [];
            this.tableSchema = this.tableSchema.filter(column => !["all", "select"].includes(column.columnDef));
        }

        this.tableSchema.forEach(column => {
            if (!this.userLastColumnConfig.includes(column.columnDef)) {
                // adjust user last preferences
                if (column.visible) {
                    column.visible = false;
                }
            }
            if (column.columnDef === this.mainColumnDef) {
                if (!column.visible) {
                    column.visible = true;
                }
            }
        });

        const userColumnWidth = localStorage.getItem(`${this.tableName}.userWidth`);
        if (userColumnWidth) {
            const userColumnWidthJSON: { key: string; width: number }[] = JSON.parse(userColumnWidth);
            this.tableSchema.forEach(column => {
                userColumnWidthJSON.forEach(userWidth => {
                    if (column.columnDef === userWidth.key) {
                        column.width = userWidth.width;
                    }
                });
            });
        }
        // this could also go in class TableListDataSource
        this.tableData.filterPredicate = (row, filter) => {
            const filters: (TableFilter | ColumnFilter)[] = JSON.parse(filter);
            for (const filter of filters) {
                if (isTableFilter(filter)) {
                    if (!this.sharedService.matches(row, filter.value, this.columnFilter)) return false;
                } else {
                    const column = this.tableSchema.find(column => column.header === filter.columnHeader);
                    const cellData = column.columnFilterValue
                        ? column.columnFilterValue(row)
                        : column.textValue
                        ? column.textValue(row)
                        : column.valueToExport(row);
                    const filterFunction = filterFunctions[column.columnFilterType]?.[filter.operatorType];
                    if (!filterFunction) {
                        return false;
                    }
                    if (!filterFunction(cellData, filter.value)) return false;
                }
            }
            return true;
        };

        // tour service
        if (this.showTour) this.tourService.initialize(this.tourSteps);

        this.tourSubscription = this.tourService.stepShow$.subscribe(step => {
            if (step.step.anchorId === "seventhTableAnchor") {
                if (this.colTrigger) this.colTrigger.closeMenu();
            }
            if (step.step.anchorId === "eigthTableAnchor") {
                if (this.colTrigger) this.colTrigger.openMenu();
            }
            if (step.step.anchorId === "tenthTableAnchor") {
                if (this.colTrigger) this.colTrigger.closeMenu();
            }
        });

        if (this.expanded) this.expandContractAllRows();

        const pageSizePreference = localStorage.getItem(this.pageSizePreferenceLocalStorageKey);
        if (pageSizePreference === "10" || pageSizePreference === "25" || pageSizePreference === "50") {
            this.pageSizeSelectedValue = parseInt(pageSizePreference, 10);
        } else {
            if (this.autoRows) {
                this.pageSizeSelectedValue = "auto";
            } else {
                this.pageSizeSelectedValue = 10;
            }
        }
    }
    private refreshData() {
        // adding text to filter by, for componentsRef columns
        this.inputListData.forEach(row => {
            this.tableSchema?.forEach(column => {
                if (column.textValue) {
                    row[`${column.columnDef}Filter`] = column.textValue(row);
                } else if (column.translate) {
                    row[`${column.columnDef}Filter`] = this.translate.instant(row[column.columnDef].toUpperCase());
                }
                if (column.sortBy) {
                    row[`${column.columnDef}SortBy`] = column.sortBy(row);
                }
            });
        });

        // Keeps rows expanded through data refresh
        if (this.showExpandButton) {
            const openIDs = this.tableData.data?.filter(r => r.isExpanded === true).map(r => r.id);
            openIDs.forEach(id => {
                const f = this.inputListData.find(x => x.id === id);
                f.isExpanded = true;
            });
        }

        this.tableData.data = this.inputListData;

        setTimeout(() => {
            this.calcTableRows();
            this.areRowsExpanded();
        }, 0);
    }

    ngAfterViewInit(): void {
        this.sortedColumnDirection = localStorage.getItem(`${this.tableName}.sortedColumnDirection`)?.split(",") ?? [
            "",
            ""
        ];
        this.sort.sort({ id: this.sortedColumnDirection[0], start: this.sortedColumnDirection[1] } as MatSortable);
        this.tableData.sort = this.sort;
        this.tableData.paginator = this.paginator;

        if (this.pageSizeSelectedValue === "auto") {
            this.calcTableRows();
        } else {
            this.tableData.paginator.pageSize = this.pageSizeSelectedValue as number;
            this.updateTableDataWithPaginatorCurrentState();
        }

        this.cd.detectChanges();
        setTimeout(() => {
            this.calcTableRows();

            if (this.paginator)
                this.emitCurrentPage({
                    pageIndex: this.tableData.paginator.pageIndex,
                    pageSize: this.tableData.paginator.pageSize,
                    length: this.tableData.paginator.length
                });
        }, 0);
    }

    private columnFilter = (row: TableObject, term: string) => {
        const filterableValues = [];

        this.tableSchema.forEach(column => {
            if (row[`${column.columnDef}Filter`]) {
                filterableValues.push(row[`${column.columnDef}Filter`]);
            } else {
                if (row[`${column.columnDef}SortBy`]) filterableValues.push(row[`${column.columnDef}SortBy`]);
            }
        });

        let matches = false;
        for (const value of filterableValues) {
            if (value.toString().toLowerCase().includes(term.toLowerCase())) matches = true;
        }

        return matches;
    };

    private pageToSelectedRow(row: TableObject) {
        setTimeout(() => {
            // gets current filtered data in current sort order
            const sortedData = this.tableData.sortData(this.tableData.filteredData, this.tableData.sort);
            const index = sortedData.findIndex(object => {
                return object.id === row.id;
            });

            const page = Math.floor(index / this.tableData.paginator.pageSize);

            if (index !== -1 && page !== -1) {
                this.tableData.paginator.pageIndex = page;
                this.updateTableDataWithPaginatorCurrentState();
            }
        }, 0);
    }

    expandContractAllRows() {
        if (this.noRowsExpanded) this.tableData.data.forEach(r => (r.isExpanded = true));
        else this.tableData.data.forEach(r => (r.isExpanded = false));
        this.areRowsExpanded();
    }

    expandButtonClick(row: TableObject) {
        row.isExpanded = !row.isExpanded;
        this.areRowsExpanded();
    }

    hasSelectCol() {
        const has = this.tableSchema?.find(i => i.columnDef === "select" && i.visible);
        if (has) return true;
        else return false;
    }

    private areRowsExpanded() {
        if (this.showExpandButton) {
            const openIDs = this.tableData.data?.filter(r => r.isExpanded === true).map(r => r.id);
            if (openIDs && openIDs.length) this.noRowsExpanded = false;
            else this.noRowsExpanded = true;
        }
    }

    private contractAllRows() {
        if (this.showExpandButton) {
            this.tableData.data.forEach(r => (r.isExpanded = false));
        }
    }

    private externalTableSelectionChanged(rowsSelected: TableObject[]) {
        this.selectedRow = null;
        this.tableSelection.clear();
        if (rowsSelected && rowsSelected.length) this.tableSelection.select(...rowsSelected);
    }

    private toggleCollapseColumnsMode(
        isColumnsCollapse: boolean,
        rowSelected?: TableObject,
        forceSelectionColumn?: boolean,
        selectedRows?: TableObject[]
    ) {
        this.isCollapseMode = isColumnsCollapse;
        if (this.isCollapseMode) {
            this.showColumnsSelection = !!forceSelectionColumn;
            this.tableSchema.forEach(column => {
                if (this.childTable) {
                    // off
                    if (
                        column.columnDef !== this.mainColumnDef &&
                        column.columnDef !== "expand" &&
                        column.columnDef !== "all" &&
                        column.columnDef !== this.sortedColumnDirection[0] &&
                        (!forceSelectionColumn || column.columnDef !== "select")
                    ) {
                        this.toggleColumn(column, true, false);
                    }
                } else {
                    // toggle columns off
                    if (
                        column.visible &&
                        column.columnDef !== this.mainColumnDef &&
                        column.columnDef !== "expand" &&
                        column.columnDef !== "all" &&
                        column.columnDef !== this.sortedColumnDirection[0] &&
                        (!forceSelectionColumn || column.columnDef !== "select")
                    ) {
                        this.toggleColumn(column);
                    }

                    // toggle columns on
                    if (!column.visible && forceSelectionColumn && column.columnDef === "select") {
                        this.toggleColumn(column);
                    }
                }
            });
            if (rowSelected) {
                if (!this.tableData.sort) return;
                this.selectedRow = rowSelected;
                this.tableSelection.clear();
                this.tableSelection.select(this.selectedRow);
                if (this.showExpandButton) {
                    const col = this.tableSchema.find(column => column.columnDef === "expand");
                    const disabled = col.expandDisabled(rowSelected);
                    if (!disabled) this.selectedRow.isExpanded = true;
                }

                // on first table load go to selected rows page
                if (this.firstLoad && this.calcRowCount) {
                    this.pageToSelectedRow(rowSelected);
                    this.firstLoad = false;
                }
                // for childTable
            } else {
                if (!selectedRows?.length) {
                    this.tableSelection.clear();
                    this.selectedRow = null;
                }
            }
        } else {
            this.showColumnsSelection = true;
            this.tableSelection.clear();
            this.selectedRow = null;
            this.userLastColumnConfig =
                localStorage
                    .getItem(`${this.tableName}.userColumns`)
                    ?.split(",")
                    .filter(us => this.tableSchema.find(cs => cs.columnDef === us)) ?? this.defaultUserColumnConfig;
            this.tableSchema.forEach(column => {
                this.userLastColumnConfig.forEach(userColumn => {
                    if (column.columnDef === userColumn && column.visible === false) {
                        column.visible = true;
                    }
                });
            });
        }

        this.emitSelectedRows();
    }

    private emitCurrentPage(pageEvent: PageEvent) {
        if (!pageEvent) {
            return;
        }
        const startIndex = pageEvent.pageIndex * pageEvent.pageSize;
        const endIndex = startIndex + pageEvent.pageSize;
        const itemsShowed = this.tableData.filteredData.slice(startIndex, endIndex);
        this.currentPage.emit(itemsShowed);
        this.currentPageInfo.emit({
            pageIndex: pageEvent.pageIndex,
            pageSize: pageEvent.pageSize
        });
    }

    rowClick(row: TableObject, event: MouseEvent) {
        if (!this.selectable && !this.showSelectionCheckbox) return;

        if ((event && event.ctrlKey && !this.isCollapseMode) || !this.selectable) {
            this.checkboxRowClick(event, row);
        } else {
            this.selectedRow = row;
            this.toggleCollapseColumnsMode(true, this.selectedRow);
            this.rowSelected.emit(this.selectedRow);
            this.ignoreNextSelectedRowsChange = true;
            this.selectedRowsChange.emit(this.tableSelection.selected);
            this.areRowsExpanded();
        }
    }

    onDragRowStart(row: TableObject, dragEvent: DragEvent) {
        this.dragRowHandler.emit({ dragEvent, row });
    }

    checkboxRowClick(event: MouseEvent, row: TableObject) {
        if (this.canDisableCheckboxes && row.disableRow) return;
        if (event) this.tableSelection.toggle(row);
        if (!this.tableSelection.selected || this.tableSelection.selected.length === 0) this.selectedRow = null;
        this.ignoreNextSelectedRowsChange = true;
        this.selectedRowsChange.emit(this.tableSelection.selected);
    }

    // For adding/removing columns from table
    toggleColumn(col: TableSchema, turnOff?: boolean, turnOn?: boolean) {
        if (!turnOff && !turnOn) col.visible = !col.visible;
        if (turnOff) col.visible = false;
        if (col.visible) {
            // add new column to to it's default position (after it's previos column from default)
            // or at the end if it has no default position
            let index = Infinity;
            const defaultIndex = this.defaultUserColumnConfig.findIndex(c => c === col.columnDef);
            if (defaultIndex > 0) {
                const prevColumn = this.defaultUserColumnConfig[defaultIndex - 1];
                const prevColumnCurrentIndex = this.userLastColumnConfig.findIndex(c => c === prevColumn);
                if (prevColumnCurrentIndex > 0) index = prevColumnCurrentIndex + 1;
            }

            const userConfig = this.userLastColumnConfig;
            userConfig.splice(index, 0, col.columnDef);
            this.userLastColumnConfig = userConfig;
        } else {
            this.userLastColumnConfig = this.userLastColumnConfig.filter(c => c !== col.columnDef);
        }
        this.tableSchema.forEach(column => {
            if (column.columnDef === col.columnDef) {
                column.visible = col.visible;
                return;
            }
        });
        this.keepStickyVisibleColumnsPlaces();
        this.calcTableRows();
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.tableSelection.selected.length;
        const numRows = this.tableData.filteredData.length;
        return numSelected >= numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle(event: MatCheckboxChange) {
        this.tableSelection.clear();
        if (!this.isAllSelected() && event.checked)
            this.tableSelection.select(...this.tableData.filteredData.filter(r => !r.disableRow));
        this.emitSelectedRows();
    }

    private emitSelectedRows() {
        this.ignoreNextSelectedRowsChange = true;
        this.selectedRowsChange.emit(this.tableSelection.selected);
    }

    // reorder column
    dropColumn(event: CdkDragDrop<string[]>) {
        // Use setTimeout to fix first column move not working on some tables
        setTimeout(() => {
            // cluster table indexes are reporting one off
            // maybe because of the expand column that is unique to that table?
            // adding +1 manually for now to fix column reorder bug on that table
            if (this.tableName === "cluster")
                moveItemInArray(this.userLastColumnConfig, event.previousIndex + 1, event.currentIndex + 1);
            else moveItemInArray(this.userLastColumnConfig, event.previousIndex, event.currentIndex);
            this.keepStickyVisibleColumnsPlaces();
        });
    }

    private keepStickyVisibleColumnsPlaces() {
        // keep sticky column as first columns
        const stickyColumnsNames = this.tableSchema
            .filter(col => col.visible && col.sticky != null && !col.stickyToLast)
            .map(stickyCol => stickyCol.columnDef);

        // prevent duplicate column names
        for (const name of stickyColumnsNames) {
            this.userLastColumnConfig.splice(this.userLastColumnConfig.indexOf(name), 1);
        }
        this.userLastColumnConfig.unshift(...stickyColumnsNames);

        // keep last sticky column at last column
        const stickyLastColumnName = this.tableSchema.find(col => col.stickyToLast)?.columnDef;
        if (stickyLastColumnName) {
            this.userLastColumnConfig.splice(this.userLastColumnConfig.indexOf(stickyLastColumnName), 1);
            this.userLastColumnConfig.push(stickyLastColumnName);
        }

        this.userLastColumnConfig = this.userLastColumnConfig.filter((item, index, inputArray) => {
            return inputArray.indexOf(item) === index;
        });
    }

    widthChanged(columnName: string, newWidth: number) {
        this.tableSchema.forEach((column, i) => {
            if (column.columnDef === columnName) {
                if (this.tableSchema[i].width !== newWidth) {
                    this.tableSchema[i].width = newWidth;
                    const table = this.tableSchema;
                    if (this.showSelectionCheckbox) {
                        this.tableSchemaChange.emit(table.slice(2, table.length));
                    } else {
                        this.tableSchemaChange.emit(table);
                    }
                }
                return;
            }
        });
        const userWidth = [];
        this.tableSchema.forEach(column => {
            userWidth.push({
                key: column.columnDef,
                width: column.width
            });
        });
        localStorage.setItem(`${this.tableName}.userWidth`, JSON.stringify(userWidth));
    }

    pageSizeSelectedValueChange(event: MatSelectChange) {
        if (!event.value) return;
        localStorage.setItem(this.pageSizePreferenceLocalStorageKey, event.value);
        this.paginator.pageIndex = 0;
        if (event.value !== "auto") {
            this.tableData.paginator.pageSize = event.value as number;
            this.updateTableDataWithPaginatorCurrentState();
        } else {
            this.calcTableRows();
        }
    }

    pageSizeOrIndexChanged(pageEvent: PageEvent) {
        this.emitCurrentPage(pageEvent);
    }

    private calcTableRows() {
        // Calc 'AUTO' row height
        const tableHeaderHeight = 37;
        let rowHeight = 32;
        if (this.userLastColumnConfig.includes("thumbnails")) rowHeight = 85;

        const fullHeight =
            this.tableContainer?.nativeElement?.offsetHeight +
            this.tableFilterContainer?.nativeElement?.offsetHeight +
            this.paginatorContainer?.nativeElement?.offsetHeight;

        const avalHeight =
            fullHeight -
            this.paginatorContainer?.nativeElement?.children[0]?.offsetHeight -
            this.tableFilterContainer?.nativeElement?.offsetHeight -
            tableHeaderHeight -
            32 -
            16;

        if (Math.sign(avalHeight) === -1) return;

        let listRows = Math.floor(avalHeight / rowHeight);

        if (listRows < 5) listRows = 5;

        if (this.tableData.paginator?.pageSize !== listRows && this.pageSizeSelectedValue === "auto") {
            if (this.tableData.paginator) {
                const previousPageIndex = this.tableData.paginator.pageIndex;
                this.tableData.paginator.pageSize = listRows;
                this.updateTableDataWithPaginatorCurrentState();

                this.emitCurrentPage({
                    pageIndex: this.tableData.paginator.pageIndex,
                    previousPageIndex: previousPageIndex,
                    pageSize: this.tableData.paginator.pageSize,
                    length: this.tableData.paginator.length
                });
            }
        }
        this.calcRowCount = listRows;
    }

    sortData(sort: Sort) {
        this.sortedColumnDirection[0] = sort.direction ? sort.active : "";
        this.sortedColumnDirection[1] = sort.direction ?? "";
        localStorage.setItem(
            `${this.tableName}.sortedColumnDirection`,
            `${this.sortedColumnDirection[0]},${this.sortedColumnDirection[1]}`
        );
        //
        this.currentSortDirection.emit(sort.direction);
        this.refreshData();
    }

    trackByFn(index: number) {
        return index;
    }

    dataTrackByFn(index: number, row) {
        return `${row.type ?? 0}${row.id}`;
    }

    resetColumnConfig() {
        // Clear localStorage
        localStorage.removeItem(`${this.tableName}.userColumns`);
        localStorage.removeItem(`${this.tableName}.userWidth`);
        localStorage.removeItem(`${this.tableName}.sortedColumnDirection`);
        // Reset Sorted Column
        this.sortedColumnDirection = localStorage.getItem(`${this.tableName}.sortedColumnDirection`)?.split(",") ?? [
            "",
            ""
        ];
        this.sort.sort({ id: this.sortedColumnDirection[0], start: this.sortedColumnDirection[1] } as MatSortable);
        this.tableData.sort = this.sort;

        if (!this.childTable) {
            // Reset Table Schema
            this.tableSchema.forEach(column => {
                // Reset Column Visibility
                if (this.storedUserColumnConfig.includes(column.columnDef) && column.visible === false)
                    this.toggleColumn(column);
                if (!this.storedUserColumnConfig.includes(column.columnDef) && column.visible === true)
                    this.toggleColumn(column);
                // Reset Width
                this.storedTableSchema.forEach(col => {
                    if (column.columnDef === col.columnDef) {
                        column.width = col.width;
                    }
                });
            });
            // Reset Order
            if (!this.showSelectionCheckbox)
                this.storedUserColumnConfig = this.storedUserColumnConfig.filter(col => col !== "select");
            if (!this.showExpandButton)
                this.storedUserColumnConfig = this.storedUserColumnConfig.filter(col => col !== "expand");
            this.userLastColumnConfig = [...this.storedUserColumnConfig];
        } else {
            this.tableSchema.forEach(column => {
                const c = this.storedTableSchema.find(c => {
                    return c.columnDef === column.columnDef;
                });

                if (!c) return;
                if (column.visible === false && c.visible === true) this.toggleColumn(column);
                if (column.visible === true && c.visible === false) this.toggleColumn(column);

                // Reset Width
                column.width = c.width;
            });

            // Reset Order
            if (!this.showSelectionCheckbox)
                this.storedUserColumnConfig = this.storedUserColumnConfig.filter(col => col !== "select");
            if (!this.showExpandButton)
                this.storedUserColumnConfig = this.storedUserColumnConfig.filter(col => col !== "expand");

            this.userLastColumnConfig = [...this.defaultUserColumnConfig];
        }
        //
        this.refreshData();
    }

    // TODO: update with user set values
    getCellBackground(row, column) {
        if (column.bgValue) {
            const n = column.bgValue(row);
            if (!n && n !== 0) return "";
            if (n > 75) return "status-error-bg";
            if (n <= 75 && n > 50) return "status-warning-bg";
            if (n <= 50) return "status-good-bg";
        }
        return "";
    }

    getCellStyle(row, column) {
        if (!column.style) {
            return "";
        }
        return column.style(row);
    }

    private get excelTitle() {
        return this.excelReportTitle || this.displayTableName;
    }

    report() {
        if (!this.excelTitle) throw new Error("export to excel failed because: excelReportTitle is not defined");

        // Create report filter string
        let filterString = "";
        if (this.tableData.filter) {
            const filters = JSON.parse(this.tableData.filter);
            for (const filter of filters) {
                if (filter.columnHeader) {
                    if (filterString) filterString = filterString + ", " + filter.columnHeader + ": ";
                    else filterString = filter.columnHeader + ": ";
                    if (operatorTypes[filter.columnType].length > 1 && filter.rawValue) {
                        filterString = filterString + this.translate.instant(filter.operatorType).toLowerCase();
                    }
                    if (filter.columnType === "duration" && filter.rawValue) {
                        filterString =
                            filterString + "'" + filter.rawValue + "' " + this.translate.instant(filter.durationType);
                    }
                    if (filter.columnType !== "duration" && filter.rawValue) {
                        filterString = filterString + "'" + filter.rawValue + "'";
                    }
                } else {
                    if (filter.value) {
                        filterString = filterString + "boolean search: '" + filter.value + "'";
                    }
                }
            }
        }

        const columnsToExport = this.tableSchema.filter(column => !!column.valueToExport);
        const headersForExcel = columnsToExport.map(column => {
            const columnDefWords = column.header.split(" ");
            return columnDefWords.map(header => header).join(" ");
        });
        const rowsToExport = this.getRowsToExport();
        const dataForExcel = rowsToExport.map(row => columnsToExport.map(column => column.valueToExport(row)));
        switch (this.excelTitle) {
            case this.translate.instant("TRANSCODING_PROFILES"):
                this.excelService.exportTranscodingProfileExcel(
                    dataForExcel,
                    headersForExcel,
                    this.excelTitle,
                    filterString
                );
                break;
            case this.translate.instant("BROADCASTER_CLUSTERS_AND_BROADCASTERS"):
                this.excelService.exportClustersExcel(dataForExcel, headersForExcel, this.excelTitle, filterString);
                break;
            default:
                this.excelService.exportExcel(dataForExcel, headersForExcel, this.excelTitle, filterString);
                break;
        }
    }

    private getRowsToExport() {
        if (this.excelTitle === this.translate.instant("BROADCASTER_CLUSTERS_AND_BROADCASTERS")) {
            const rowsToExport = [];
            this.selectedRow
                ? rowsToExport.push(this.selectedRow, ...(this.selectedRow as unknown as Cluster).broadcasters)
                : this.tableSelection.selected.length
                ? (this.tableSelection.selected as unknown as Cluster[]).forEach(rowToExport =>
                      rowsToExport.push(rowToExport, ...rowToExport.broadcasters)
                  )
                : (this.tableData.filteredData as unknown as Cluster[]).forEach(rowToExport =>
                      rowsToExport.push(rowToExport, ...rowToExport.broadcasters)
                  );
            return rowsToExport;
        }
        if (this.selectedRow) return [this.selectedRow];
        if (this.tableSelection.selected.length) return this.tableSelection.selected;
        return this.tableData.filteredData;
    }

    getTourIDs(column) {
        if (!this.showTour) return "";

        const arr = [...this.storedUserColumnConfig];
        arr.shift();
        arr.shift();
        const slicedArray = arr.slice(0, 3);
        if (slicedArray.includes(column.columnDef)) {
            if (slicedArray.indexOf(column.columnDef) === 0) return "fifthTableAnchor";
            if (slicedArray.indexOf(column.columnDef) === 1) return "sixthTableAnchor";
            if (slicedArray.indexOf(column.columnDef) === 2) return "seventhTableAnchor";
        } else return "";
    }

    countOfColumnsInChooser() {
        const chooserOptions = this.tableSchema.filter(r => !r.hideFromColumnChooser);
        return chooserOptions ? chooserOptions.length : 0;
    }

    private updateTableDataWithPaginatorCurrentState() {
        this.paginator.page.emit();
    }
}
