import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";
import { ControlContainer, NgForm } from "@angular/forms";

import { TargetsService } from "../../../pages/targets/targets.service";
import { AnyTarget, TargetApiType } from "../../../pages/channels/channel";
import { SomeZixiObject } from "src/app/models/shared";

@Component({
    selector: "zx-targets-select",
    template: `
        <!-- ID and Type -->
        <ng-select
            *ngIf="useIDs"
            class="form-control"
            [ngClass]="{ 'form-control-sm': size === 'small' }"
            name="{{ name }}"
            [disabled]="disabled"
            [items]="targets"
            [loading]="loading"
            [multiple]="true"
            [hideSelected]="true"
            [closeOnSelect]="false"
            [clearSearchOnAdd]="true"
            bindLabel="name"
            bindValue="id"
            [searchFn]="customSearchFn"
            [placeholder]="placeholder | translate"
            (change)="modelChanged()"
            [(ngModel)]="model"
            required="{{ required || false }}"
        >
            <ng-template ng-label-tmp let-item="item" let-clear="clear" *ngIf="!loading">
                <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true" *ngIf="item.target">×</span>
                <span class="ng-value-label" *ngIf="item.target"
                    >{{ item.type_name }}:&nbsp;<zx-status-icon [model]="item.target" class="me-1"></zx-status-icon
                    >{{ item.target.name }}</span
                >
            </ng-template>
            <ng-template ng-label-tmp let-item="item" *ngIf="loading"> {{ "LOADING" | translate }}... </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                {{ item.type_name }}:&nbsp;<zx-status-icon [model]="item.target" class="me-1"></zx-status-icon
                >{{ item.target.name }}
            </ng-template>
        </ng-select>

        <!-- Any Target -->
        <ng-select
            *ngIf="!useIDs"
            class="form-control"
            [ngClass]="{ 'form-control-sm': size === 'small' }"
            name="{{ name }}"
            [disabled]="disabled"
            [items]="targets"
            [loading]="loading"
            [multiple]="true"
            [hideSelected]="true"
            [closeOnSelect]="false"
            [clearSearchOnAdd]="true"
            [searchFn]="customSearchFn"
            [placeholder]="placeholder | translate"
            (change)="modelChanged()"
            [(ngModel)]="model"
            required="{{ required || false }}"
        >
            <ng-template ng-label-tmp let-item="item" let-clear="clear" *ngIf="!loading">
                <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
                <span class="ng-value-label"
                    >{{ item.type_name }}:&nbsp;<zx-status-icon [model]="item.target" class="me-1"></zx-status-icon
                    >{{ item.target.name }}</span
                >
            </ng-template>
            <ng-template ng-label-tmp let-item="item" *ngIf="loading"> {{ "LOADING" | translate }}... </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                {{ item.type_name }}:&nbsp;<zx-status-icon [model]="item.target" class="me-1"></zx-status-icon
                >{{ item.target.name }}
            </ng-template>
        </ng-select>
    `,
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ZxTargetsSelectComponent implements OnInit, OnDestroy {
    @Input() name: string;
    @Input() model: SomeZixiObject[] | string[];
    @Input() useIDs?: boolean;
    @Input() size?: string;
    @Input() required?: boolean;
    @Input() disabled?: boolean;
    @Input() placeholder?: string;
    @Input() apiType?: TargetApiType;
    @Input() adaptive?: boolean;
    @Input() delivery?: boolean;
    @Input() mediaconnect?: boolean;
    @Input() medialive?: boolean;
    @Output() modelChange = new EventEmitter();

    loading: boolean;
    targets: AnyTarget[];
    private targetsSubscription: Subscription;

    constructor(private ts: TargetsService) {}

    modelChanged() {
        this.modelChange.emit(this.model);
    }

    async ngOnInit() {
        this.loading = true;
        if (!this.placeholder) this.placeholder = "SELECT_TARGETS";

        if (!this.apiType) {
            if (this.adaptive) await this.ts.getAdaptiveTargets();
            else if (this.delivery) await this.ts.getDeliveryTargets();
            else await this.ts.getAllTargets();
        } else {
            this.ts.refreshTargets(this.apiType);
        }

        this.targetsSubscription = this.ts.targets.subscribe(targets => {
            if (!this.apiType) {
                if (this.adaptive) {
                    this.targets = [...targets.filter(t => t.adaptive === true)];
                } else if (this.delivery) {
                    this.targets = [...targets.filter(t => t.delivery === true)];
                } else if (this.mediaconnect) {
                    this.targets = [...targets.filter(t => t.mediaconnect === true)];
                } else if (this.medialive) {
                    this.targets = [...targets.filter(t => t.medialive === true)];
                } else {
                    this.targets = [...targets];
                }
            } else {
                this.targets = [...targets.filter(t => t.apiType === this.apiType)];
            }
        });

        // Remove ids from model when source does not exist
        if (this.useIDs) {
            const modelIds = [];
            for (const id of this.model as string[]) {
                if (this.targets.some(target => target.id === id)) {
                    modelIds.push(id);
                }
            }
            this.model = modelIds;
        }

        this.loading = false;
    }

    ngOnDestroy() {
        this.targetsSubscription.unsubscribe();
    }

    customSearchFn(term: string, item: AnyTarget) {
        term = term.toLowerCase();
        return item.target.name.toLowerCase().indexOf(term) > -1 || item.type_name.toLowerCase().indexOf(term) > -1;
    }
}
