import { Component, OnDestroy } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "app-error-dialog",
    templateUrl: "./error-dialog.component.html"
})
export class ErrorDialogComponent implements OnDestroy {
    errorText: string;
    private routeSubscription: Subscription;

    constructor(public activeModal: NgbActiveModal, private router: Router) {
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => {
                // Close modal on navigation event
                this.activeModal.close();
            });
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
    }
}
