import { Component, Input, OnInit, Self, inject } from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { firstValueFrom } from "rxjs";
import { Tag } from "../../../../models/shared";
import { SharedService } from "../../../../services/shared.service";
import { TooltipConfigs } from "../../utilities/zx-forms-tooltips-configs";
import { ZxFormsHelper } from "../../utilities/zx-forms-helper";
/**
 * Implementation Notes:
 * To show validation error use the the validators defined in ZxFormsValidators instead of Angular's built-in Validators.
 * See validators.ts and zx-forms-field-errors.component.ts for details.
 */

@Component({
    selector: "zx-forms-tags-select",
    templateUrl: "./zx-forms-tags-select.component.html"
})
export class ZxFormsTagsSelectComponent implements OnInit, ControlValueAccessor {
    @Input() isParentFormSubmitted: boolean;
    @Input() isReadOnlyTags = false; // this parameter called 'ro' in the old implementation
    @Input() tagsType?: string;
    @Input() tooltipsConfigs?: TooltipConfigs[];
    tags: Tag[];
    inputId = ZxFormsHelper.generateId("tags_select");
    selectedTagsIds: number[];
    notifyControlOnChange: Function;
    notifyControlOnTouch: Function;
    isLoading: boolean;
    isDisabled: boolean;
    private readonly MOST_RECENT_TAGS_MAX_QUANTITY = 5;
    private readonly MOST_RECENT_TAGS_LOCAL_STORAGE_KEY = "recent.resourceTags";
    private sharedService = inject(SharedService);

    constructor(@Self() public ngControl: NgControl) {
        this.ngControl.valueAccessor = this;
    }

    get isInvalid(): boolean {
        return (
            this.ngControl.control.invalid &&
            (this.ngControl.control.touched || this.ngControl.control.dirty || this.isParentFormSubmitted)
        );
    }

    async ngOnInit(): Promise<void> {
        this.isLoading = true;
        this.tags = await this.getTags();
        this.isLoading = false;
    }

    registerOnChange(fn: any): void {
        this.notifyControlOnChange = fn;
    }

    writeValue(selectedTagsIds: number[]): void {
        this.selectedTagsIds = selectedTagsIds;
    }

    registerOnTouched(fn: any): void {
        this.notifyControlOnTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    async getTags() {
        const tags = await firstValueFrom(this.sharedService.getResourceTagsByType(this.tagsType, this.isReadOnlyTags));
        this.sharedService.sort(tags, "name", "asc");
        const mostRecentTagsIds = this.getMostRecentTagsIds().reverse();
        mostRecentTagsIds.forEach(id => {
            const tagIndex = tags.findIndex(tag => tag.id === id);
            if (tagIndex !== -1) {
                const removedTag = tags.splice(tagIndex, 1)[0];
                tags.unshift(removedTag);
            }
        });
        return tags;
    }

    updateMostRecentTagsIds() {
        const startIndex = Math.max(this.selectedTagsIds.length - this.MOST_RECENT_TAGS_MAX_QUANTITY, 0);
        const lastSelectedTags = this.selectedTagsIds.slice(startIndex);
        const mostRecentTags = this.getMostRecentTagsIds();
        lastSelectedTags.forEach(id => {
            const isTagAlreadyInMostRecentTags = mostRecentTags.includes(id);
            if (!isTagAlreadyInMostRecentTags) {
                mostRecentTags.unshift(id);
            }
        });
        localStorage.setItem(
            this.MOST_RECENT_TAGS_LOCAL_STORAGE_KEY,
            mostRecentTags.slice(0, this.MOST_RECENT_TAGS_MAX_QUANTITY).toString()
        );
    }

    getMostRecentTagsIds() {
        const mostRecentTagsIdsString = localStorage.getItem(this.MOST_RECENT_TAGS_LOCAL_STORAGE_KEY);
        return mostRecentTagsIdsString ? mostRecentTagsIdsString.split(",").map(Number) : [];
    }
}
