import { Component, OnDestroy } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { Subscription } from "rxjs";
import { filter, take } from "rxjs/operators";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Broadcaster } from "./../../../../models/shared";
import { UsersService } from "../../../../pages/account-management/users/users.service";
import { ClipboardService } from "ngx-clipboard";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-broadcaster-config-help-dialog",
    templateUrl: "./broadcaster-config-help-dialog.component.html"
})
export class BroadcasterConfigHelpDialogComponent implements OnDestroy {
    broadcaster: Broadcaster;
    tunnelersHost: string;

    private routeSubscription: Subscription;

    constructor(
        public activeModal: NgbActiveModal,
        private router: Router,
        private userService: UsersService,
        private cbs: ClipboardService,
        private translate: TranslateService
    ) {
        this.userService.getTunnelersHost.pipe(take(1)).subscribe(host => {
            this.tunnelersHost = host;
        });

        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => {
                // Close modal on navigation event
                this.activeModal.close();
            });
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
    }

    copyString(pw: string) {
        this.cbs.copy(pw);
    }
}
