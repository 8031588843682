import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Source, TraceRoute } from "src/app/models/shared";
import { Subscription, filter, firstValueFrom, interval } from "rxjs";
import { NavigationStart, Router } from "@angular/router";
import { SourcesService } from "../../sources.service";
import { SharedService } from "src/app/services/shared.service";

@Component({
    selector: "app-source-traceroute-dialog",
    templateUrl: "./source-traceroute-dialog.component.html"
})
export class SourceTracerouteDialogComponent implements OnDestroy, OnInit {
    source: Source;
    loading: boolean;
    traceroute: TraceRoute;
    tracerouteEmpty = false;

    private routeSubscription: Subscription;
    private tracerouteSubscription: Subscription;
    private refreshSubscription: Subscription;

    constructor(
        public activeModal: NgbActiveModal,
        private router: Router,
        private ss: SourcesService,
        private sharedService: SharedService,
        private translate: TranslateService
    ) {
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => {
                // Close modal on navigation event
                this.activeModal.close();
            });
    }

    async ngOnInit() {
        this.loading = true;
        await firstValueFrom(this.ss.refreshTraceroute(this.source));

        this.tracerouteSubscription = this.ss.traceroute.subscribe(async traceroute => {
            this.traceroute = traceroute;
            if (this.traceroute) {
                // Empty
                if (this.sharedService.isEmptyObject(this.traceroute)) {
                    this.tracerouteEmpty = true;
                } else this.tracerouteEmpty = false;

                if (this.traceroute.s_status === "running" && this.traceroute.status === 1) {
                    // Get traceroute again?
                }
            }
        });

        this.loading = false;
        // Start  Refresh
        this.startRefresh();
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
        this.tracerouteSubscription.unsubscribe();
        this.stopRefresh();
    }

    startRefresh() {
        this.refreshSubscription = interval(30000).subscribe(() => {
            this.ss.refreshTraceroute(this.source);
        });
    }

    stopRefresh() {
        this.refreshSubscription.unsubscribe();
    }

    urlEncodedTraceroute(s: string) {
        return encodeURIComponent(s.replace(/^[^\n]+\n/, ""));
    }
}
