import { Component, Input, Self, inject } from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { TooltipConfigs, ZX_FORMS_TOOLTIPS_CONFIGS } from "../../utilities/zx-forms-tooltips-configs";
import { ZxFormsHelper } from "../../utilities/zx-forms-helper";
import { MapService } from "../../../../pages/maps/map.service";
import { firstValueFrom } from "rxjs";
import { debounce } from "lodash";
import { NominatimResponse, LocationInfo } from "src/app/pages/maps/map";
import { isNumber } from "lodash";

@Component({
    selector: "zx-forms-input-location",
    templateUrl: "./zx-forms-input-location.component.html",
    styleUrls: ["./zx-forms-input-location.component.scss"]
})
export class ZxFormsInputLocationComponent implements ControlValueAccessor {
    // Required Inputs
    @Input() isSubmitted: boolean;
    // Optional Inputs
    @Input()
    set tooltipsConfigs(tooltipsConfigs: TooltipConfigs[] | undefined) {
        this.locationTooltipsConfigs = tooltipsConfigs
            ? [...tooltipsConfigs, ZX_FORMS_TOOLTIPS_CONFIGS.LOCATION_INFO]
            : [ZX_FORMS_TOOLTIPS_CONFIGS.LOCATION_INFO];
    }
    // Variables
    inputId = ZxFormsHelper.generateId("input_location");
    selectedLocation: ZxFormsInputLocationValue = null;
    locationTooltipsConfigs: TooltipConfigs[] = [ZX_FORMS_TOOLTIPS_CONFIGS.LOCATION_INFO];
    notifyControlOnChange: Function;
    notifyControlOnTouch: Function;
    searchValue: string = null;
    isSearchOpen = false;
    isLoading = false;
    isDisabled = false;
    searchResults: NominatimResponse[] = null;
    // Services
    private mapService = inject(MapService);

    constructor(@Self() public ngControl: NgControl) {
        this.ngControl.valueAccessor = this;
        this.searchHandler = debounce(this.searchHandler, 500);
    }

    get isInvalid(): boolean {
        return (
            this.ngControl.control.invalid &&
            (this.ngControl.control.touched || this.ngControl.control.dirty || this.isSubmitted)
        );
    }

    get isValidSearch() {
        return this.searchValue?.length > 3;
    }

    async searchHandler() {
        this.clearSearchResults();
        this.isLoading = true;
        this.searchResults = await firstValueFrom(this.mapService.addressLookup(this.searchValue));
        this.isLoading = false;
    }

    selectLocationHandler(event: MouseEvent, location: NominatimResponse) {
        event.preventDefault();
        this.updateSelectedLocation({
            latitude: location.latitude,
            longitude: location.longitude,
            display_name: location.displayName,
            source: "address",
            city: location.address.city || location.address.town || location.address.village || location.address.hamlet,
            region_name: location.address.state,
            country_name: location.address.country
        });
    }

    clearSearchInput() {
        this.searchValue = null;
    }

    clearSearchResults() {
        this.searchResults = null;
    }

    updateSelectedLocation(location: ZxFormsInputLocationValue | null) {
        this.selectedLocation = location;
        this.notifyControlOnChange(location);
    }

    writeValue(location: ZxFormsInputLocationValue): void {
        this.selectedLocation = location;
    }

    registerOnChange(fn: any): void {
        this.notifyControlOnChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.notifyControlOnTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}

export type ZxFormsInputLocationValue = {
    source: string;
    display_name: string;
    latitude: number | null;
    longitude: number | null;
    city: string;
    region_name: string;
    country_name: string;
};
export function convertToZxFormsInputLocationValue(objectLocation: {
    ip?: LocationInfo;
    address?: LocationInfo;
}): null | ZxFormsInputLocationValue {
    if (objectLocation?.ip) {
        return convert(objectLocation.ip);
    } else if (objectLocation?.address) {
        return convert(objectLocation.address);
    }
    return null;
}
function convert(locationInfo: LocationInfo): ZxFormsInputLocationValue {
    const { display_name, source, latitude, longitude, city, region_name, country_name } = locationInfo;
    return {
        display_name,
        source,
        latitude: isNumber(latitude) ? latitude : null,
        longitude: isNumber(longitude) ? longitude : null,
        city: city || null,
        region_name: region_name || null,
        country_name: country_name || null
    };
}
