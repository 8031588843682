import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";
import { ControlContainer, NgForm } from "@angular/forms";

import { Feeder, Receiver, Zec } from "../../../pages/zecs/zecs/zec";
import { ZecsService } from "../../../pages/zecs/zecs.service";
import { ZecTypes } from "src/app/pages/zecs/zec-list/zec-list.component";

@Component({
    selector: "zx-zecs-select",
    template: `
        <ng-select
            class="form-control"
            [ngClass]="{ 'form-control-sm': size === 'small' }"
            name="{{ name }}"
            [items]="zecs"
            [loading]="loading"
            [multiple]="true"
            [hideSelected]="true"
            [closeOnSelect]="false"
            [clearSearchOnAdd]="true"
            bindLabel="name"
            bindValue="id"
            placeholder="{{ 'SELECT' | translate }} {{ zecType | translate }}"
            (change)="modelChanged()"
            [(ngModel)]="model"
            [disabled]="disabled"
            required="{{ required || false }}"
        >
            <ng-template ng-label-tmp let-item="item" let-clear="clear" *ngIf="!loading">
                <span *ngIf="item.name" class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
                <span *ngIf="item.name" class="ng-value-label"
                    ><zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name }}</span
                >
            </ng-template>
            <ng-template ng-label-tmp let-item="item" *ngIf="loading"> {{ "LOADING" | translate }}... </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name }}
            </ng-template>
        </ng-select>
    `,
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ZxZecsSelectComponent implements OnInit, OnDestroy {
    @Input() name: string;
    @Input() model: number[];
    @Input() size?: string;
    @Input() required?: boolean;
    @Input() disabled?: boolean;
    @Input() zecType? = "ZECS";
    @Output() modelChange = new EventEmitter();

    loading: boolean;
    zecs: ZecTypes[];
    private zecsSubscription: Subscription;

    constructor(private rs: ZecsService) {}

    modelChanged() {
        this.modelChange.emit(this.model);
    }

    ngOnInit() {
        this.loading = true;
        this.zecType = this.zecType.toUpperCase();
        switch (this.zecType) {
            case "FEEDERS":
                this.getFeedersToSelect();
                break;
            case "RECEIVERS":
                this.getReceiversToSelect();
                break;
            default:
                this.getZecsToSelect();
        }

        this.loading = false;
    }

    getZecsToSelect() {
        this.rs.refreshZecs("ZEC");
        this.zecsSubscription = this.rs.zecs$.subscribe(zecs => {
            this.zecs = [...zecs] as Zec[];

            const modelIds = [];
            if (this.model && this.model.length)
                for (const id of this.model) {
                    if (this.zecs.some(zec => zec.id === id)) {
                        modelIds.push(id);
                    }
                }

            this.model = modelIds;
        });
    }

    getFeedersToSelect() {
        this.rs.refreshZecs("FEEDER");
        this.zecsSubscription = this.rs.feeders$.subscribe(feeders => {
            this.zecs = [...feeders] as Feeder[];

            const modelIds = [];
            if (this.model && this.model.length)
                for (const id of this.model) {
                    if (this.zecs.some(feeder => feeder.id === id)) {
                        modelIds.push(id);
                    }
                }

            this.model = modelIds;
        });
    }

    getReceiversToSelect() {
        this.rs.refreshZecs("RECEIVER");
        this.zecsSubscription = this.rs.receivers$.subscribe(receivers => {
            this.zecs = [...receivers] as Receiver[];

            const modelIds = [];
            if (this.model && this.model.length)
                for (const id of this.model) {
                    if (this.zecs.some(receiver => receiver.id === id)) {
                        modelIds.push(id);
                    }
                }

            this.model = modelIds;
        });
    }

    ngOnDestroy() {
        this.zecsSubscription.unsubscribe();
    }
}
