import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { firstValueFrom } from "rxjs";
import { UpdateRuleData } from "../edit-alerting-rule/edit-alerting-rule.component";
import { AlertingProfile, AlertingRule } from "../events-management";
import { EventsManagementService } from "../events-management.service";
import { Constants } from "src/app/constants/constants";
import { EditRuleDialogService } from "./edit-rule-dialog.service";
import { RulesForm } from "../alerting-profile-form/alerting-profile-form.component";

export interface EditRuleDialogData {
    created_at: string;
    error_code: string;
    error_group: string;
    id: string;
    message: string;
    object_id: number;
    object_type: string;
    short_message: string;
    updated_at: string;
    object_name: string;
}
@Component({
    selector: "app-edit-rule-dialog",
    templateUrl: "./edit-rule-dialog.component.html",
    styleUrls: ["./edit-rule-dialog.component.scss"]
})
export class EditRuleDialogComponent implements OnInit {
    @ViewChild("form") form: RulesForm;
    nameControl = new FormControl("", Validators.required);
    loading = true;
    saving = false;
    rule: AlertingRule;
    alertingProfile: AlertingProfile;
    private _savingNewProfile = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public event: EditRuleDialogData,
        public dialogRef: MatDialogRef<EditRuleDialogComponent>,
        private eventsManagementService: EventsManagementService,
        private editRuleDialogService: EditRuleDialogService
    ) {}

    async ngOnInit(): Promise<void> {
        const host = window.location.origin;
        const alertingProfileWithOutRules = await this.eventsManagementService.getEventAlertingProfile(
            this.event.object_id,
            this.event.object_type
        );
        if (!alertingProfileWithOutRules) {
            window.open(host + "/" + Constants.urls.configuration.eventsManagement, "_blank");
            this.dialogRef.close();
        } else {
            this.alertingProfile = await firstValueFrom(
                this.eventsManagementService.getAlertingProfile(alertingProfileWithOutRules.id)
            );
            if (this.alertingProfile.default) this.savingNewProfile = true;

            this.rule = this.alertingProfile.alertingRules.find(rule => this.compareRuleToEvent(rule));
            this.loading = false;
            // TODO not every event has matching rule
            if (!this.rule) {
                // eslint-disable-next-line no-console
                console.error("Could not find matching rule");
                this.dialogRef.close();
            }
        }
    }

    get isRuleChanged(): boolean {
        return this?.form?.dirty || false;
    }

    compareRuleToEvent(rule: AlertingRule): boolean {
        const strictCompare =
            rule.object_type === this.event.object_type &&
            rule.code === this.event.error_code &&
            rule.group === this.event.error_group;
        return (
            strictCompare ||
            (rule.object_type === this.getRuleObjectType() &&
                rule.code === this.event.error_code &&
                rule.group === this.event.error_group)
        );
    }

    onRuleUpdate(data: UpdateRuleData) {
        if (data.success) this.dialogRef.close();
    }

    set savingNewProfile(isSavingNewProfile: boolean) {
        this.nameControl.reset();
        this._savingNewProfile = isSavingNewProfile;
    }

    get savingNewProfile() {
        return this._savingNewProfile;
    }

    async save() {
        this.saving = true;
        let success = false;
        const model = this.eventsManagementService.convertRuleToServer(this.rule);
        if (this.savingNewProfile) {
            const alertingRulesToAdd: AlertingRule[] = [model as AlertingRule];

            if (!this.alertingProfile.default) {
                const profileRules = await this.eventsManagementService.getAlertingRules(this.alertingProfile.id);
                if (profileRules)
                    alertingRulesToAdd.push(
                        ...(profileRules.map(this.eventsManagementService.convertRuleToServer) as AlertingRule[])
                    );
            }
            const newAlertingProfile = await this.eventsManagementService.addAlertingProfile({
                name: this.nameControl.value,
                alertingRules: alertingRulesToAdd
            });
            if (newAlertingProfile) {
                await this.editRuleDialogService.updateObjectAlertingProfile(this.event, {
                    alerting_profile_id: newAlertingProfile.id
                });
            }
            success = !!newAlertingProfile;
        } else {
            success = !!(await this.eventsManagementService.addAlertingRule(this.alertingProfile.id, model));
        }

        this.saving = false;
        this.onRuleUpdate({ success } as UpdateRuleData);
    }

    private getRuleObjectType() {
        switch (this.event.object_type) {
            case "publishing_target":
            case "ndi_targets":
            case "rist":
            case "rtmp_push":
            case "srt_targets":
            case "udp_rtp":
            case "zixi_pull":
            case "zixi_push":
            case "mediaconnect_cdi_targets":
            case "mediaconnect_jpegxs_targets":
            case "medialive_http_targets":
            case "mediaconnect_entitlement_targets":
                return "target";
            case "mediaconnect_flows":
            case "mediaconnect_sources":
            case "medialive_channels":
                return "mediaconnect";
            case "feeder":
            case "receiver":
                return "zec";
            case "failover_channel":
                return "source";
            default:
                return this.event.object_type;
        }
    }
}
