<div>
    <div class="modal-header">
        <h3 class="modal-title">{{ "ALT_PATH" | translate }} {{ "CONFIRMATION_DIALOG" | translate }}</h3>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="list-container overflow-y-hidden mb-2" *ngIf="sources.length > 0 ? true : loading">
            <!-- Table -->
            <app-table-list
                [tableName]="'disaster_recovery_sources'"
                [displayTableName]="'SOURCES' | translate"
                [showFilter]="false"
                [selectable]="false"
                [expanded]="true"
                [showColumnsSelection]="false"
                [canDisableCheckboxes]="true"
                [showReport]="false"
                [showPagination]="false"
                [refreshing]="loading"
                [data]="sources"
                [tableSchema]="sourcesTableColumnsSchema"
                [(selectedRows)]="selectedSourcesRows"
            >
            </app-table-list>
        </div>
        <div class="list-container overflow-y-hidden my-2" *ngIf="channels.length > 0">
            <!-- Table -->
            <app-table-list
                [tableName]="'disaster_recovery_channels'"
                [displayTableName]="'CHANNELS' | translate"
                [showFilter]="false"
                [selectable]="false"
                [expanded]="true"
                [showColumnsSelection]="false"
                [canDisableCheckboxes]="true"
                [showReport]="false"
                [showPagination]="false"
                [refreshing]="loading"
                [data]="channels"
                [tableSchema]="channelsTableColumnsSchema"
                [(selectedRows)]="selectedChannelRows"
            >
            </app-table-list>
        </div>
        <div class="list-container overflow-y-hidden mt-2" *ngIf="targets.length > 0">
            <!-- Table -->
            <app-table-list
                [tableName]="'disaster_recovery_targets'"
                [displayTableName]="'TARGETS' | translate"
                [showFilter]="false"
                [selectable]="false"
                [expanded]="true"
                [showColumnsSelection]="false"
                [canDisableCheckboxes]="true"
                [showReport]="false"
                [showPagination]="false"
                [refreshing]="loading"
                [data]="targets"
                [tableSchema]="targetsTableColumnsSchema"
                [(selectedRows)]="selectedTargetRows"
            >
            </app-table-list>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close()" ngbAutofocus [disabled]="disableInputs">
            {{ "CLOSE" | translate }}
        </button>
        <button
            type="button"
            class="btn btn-primary"
            [ngClass]="{ 'btn-danger': errorButton }"
            ngbAutofocus
            (click)="submit()"
            [disabled]="!(selectedSourcesRows.length || selectedChannelRows.length || selectedTargetRows.length) || disableInputs"
        >
            {{ "CONFIRM" | translate }}
        </button>
    </div>
</div>
