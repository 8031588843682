<div class="form-group">
    <div>
        <!-- Selected Location -->
        <zx-forms-field-label translateKeyLabel="LOCATION" [tooltipsConfigs]="locationTooltipsConfigs" [inputId]="inputId"></zx-forms-field-label>
        <div class="input-group">
            <!-- Show Selected Location Text Input -->
            <input
                [id]="inputId"
                type="text"
                class="form-control"
                [ngClass]="{ 'is-invalid': isInvalid }"
                [value]="selectedLocation?.display_name"
                placeholder="{{ 'LOCATION' | translate }}"
                disabled
            />
            <!-- Clear Selected Location Button -->
            <button *ngIf="selectedLocation" type="button" class="btn btn-sm btn-outline-light" [ngClass]="{ 'is-invalid': isInvalid }" [disabled]="isDisabled">
                <fa-icon icon="times" size="md" (click)="updateSelectedLocation(null)"></fa-icon>
            </button>
            <!-- Open/Close Search Button -->
            <button
                class="btn btn-outline-primary"
                [ngClass]="{ 'is-invalid': isInvalid }"
                type="button"
                (click)="isSearchOpen = !isSearchOpen"
                (blur)="notifyControlOnTouch()"
                [disabled]="isDisabled"
            >
                <fa-icon *ngIf="!isSearchOpen" icon="pencil" size="sm"></fa-icon>
                <fa-layers *ngIf="isSearchOpen" [fixedWidth]="true">
                    <fa-icon icon="slash"></fa-icon>
                    <fa-icon icon="pencil" size="sm"></fa-icon>
                </fa-layers>
            </button>
        </div>
    </div>
    <!-- Location Search & Results -->
    <div *ngIf="isSearchOpen && !isDisabled">
        <!-- Location Search -->
        <div class="input-group mt-2 mb-1">
            <!-- Location Search Button -->
            <button class="btn btn-primary" type="button" [disabled]="!isValidSearch" (click)="isValidSearch && searchHandler()">
                <fa-icon icon="search"></fa-icon>
            </button>
            <!-- Location Search Text Input -->
            <input
                type="text"
                class="form-control"
                placeholder="{{ 'SEARCH_LOCATION' | translate }}..."
                [(ngModel)]="searchValue"
                (ngModelChange)="isValidSearch && searchHandler()"
            />
            <!-- Clear Location Search Button -->
            <button *ngIf="searchValue?.length" class="btn btn-sm btn-outline-light" type="button" (click)="clearSearchInput(); clearSearchResults()">
                <fa-icon icon="times" size="md"></fa-icon>
            </button>
        </div>
        <!-- Results -->
        <div *ngIf="isValidSearch" class="location-results-list">
            <p *ngIf="isLoading && searchResults === null" class="mb-0">{{ "SEARCHING" | translate }}...</p>
            <!-- Result: Location List -->
            <h5 class="sr-only">{{ "RESULTS" | translate }}</h5>
            <p class="mb-0" *ngIf="!isLoading && searchResults?.length === 0">{{ "NO_SEARCH_RESULTS" | translate }}</p>
            <ul *ngIf="searchResults?.length && !isLoading">
                <li class="ellipsis" *ngFor="let location of searchResults" (click)="selectLocationHandler($event, location)">
                    <a href="javascript:void(0)" title="{{ location.displayName }}">{{ location.displayName }}</a>
                </li>
            </ul>
        </div>
    </div>
    <zx-forms-field-errors *ngIf="isInvalid" [errors]="ngControl.control.errors"></zx-forms-field-errors>
</div>
