import { Component, Input, Self, inject } from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { TooltipConfigs } from "../../utilities/zx-forms-tooltips-configs";
import { ZxFormsHelper } from "../../utilities/zx-forms-helper";
import { ClipboardService } from "ngx-clipboard";

/**
 * Implementation Notes:
 * To show validation error use the the validators defined in ZxFormsValidators instead of Angular's built-in Validators.
 * See validators.ts and zx-forms-field-errors.component.ts for details.
 */

@Component({
    selector: "zx-forms-text-input",
    templateUrl: "./zx-forms-text-input.component.html",
    styleUrls: ["./zx-forms-text-input.component.scss"]
})
export class ZxFormsTextInputComponent implements ControlValueAccessor {
    // Required Inputs
    @Input() translateKey: { label: string; placeholder?: string; bottomLabel?: string };
    @Input() isParentFormSubmitted: boolean;
    // Optional Inputs
    @Input() hasCopyButton = false;
    @Input() autoComplete: "cc-csc" | "off" = "off";
    @Input() tooltipsConfigs: TooltipConfigs[] = [];
    // Services
    private clipboardService = inject(ClipboardService);

    // Variables
    value: string;
    inputId = ZxFormsHelper.generateId("text_input");
    isDisabled: boolean;
    notifyControlOnChange: Function;
    notifyControlOnTouch: Function;

    constructor(@Self() public ngControl: NgControl) {
        this.ngControl.valueAccessor = this;
    }

    get isInvalid(): boolean {
        return (
            this.ngControl.control.invalid &&
            (this.ngControl.control.touched || this.ngControl.control.dirty || this.isParentFormSubmitted)
        );
    }

    copy() {
        this.clipboardService.copy(this.value);
    }

    writeValue(value: string): void {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.notifyControlOnChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.notifyControlOnTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}
