<div class="zx-forms-field-error" *ngIf="zxValidationErrors?.zxValidationRequired">
    {{ zxValidationErrors.zxValidationRequired.translateKeyError | translate }}
</div>
<div class="zx-forms-field-error" *ngIf="zxValidationErrors?.zxValidationsMinLength">
    {{ "MUST_BE_AT_LEAST" | translate }} {{ zxValidationErrors.zxValidationsMinLength.requiredLength }} {{ "CHARACTERS_LONG" | translate }}
</div>
<div class="zx-forms-field-error" *ngFor="let zxValidationRegexPatternError of zxValidationRegexPatternErrors">
    {{ zxValidationRegexPatternError.zxValidationRegexPattern.translateKeyError | translate }}
</div>
<div class="zx-forms-field-error" *ngIf="zxValidationErrors?.zxValidationDuplicateValue">{{ "MUST_BE_UNIQUE" | translate }}</div>
