import { Component, Input } from "@angular/core";
import { TooltipConfigs } from "../../utilities/zx-forms-tooltips-configs";

@Component({
    selector: "zx-forms-field-label",
    templateUrl: "./zx-forms-field-label.component.html",
    styleUrls: ["./zx-forms-field-label.component.scss"]
})
export class ZxFormsFieldLabelComponent {
    // Required Inputs
    @Input() inputId: string;
    @Input() tooltipsConfigs: TooltipConfigs[];
    @Input() translateKeyLabel: string;
}
