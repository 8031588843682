import { Component, OnDestroy, OnInit } from "@angular/core";
import { take } from "rxjs/operators";

import { Constants } from "../../../../constants/constants";
import { SourcesService } from "../../sources.service";
import { Source, WebRTCInitResponse, WebRTCConnectResponse } from "../../../../models/shared";
import { ActivatedRoute, Router } from "@angular/router";
import { SharedService } from "src/app/services/shared.service";
import { NavigationService } from "src/app/components/navigation/navigation.service";
import { urlBuilder } from "@zixi/shared-utils";

@Component({
    selector: "app-source-webrtc-video",
    templateUrl: "./source-webrtc-video.component.html"
})
export class SourceWebrtcVideoComponent implements OnInit, OnDestroy {
    source: Source = null;
    sourceId: number;

    loading = true;
    loadingWebRTC = true;
    urls = Constants.urls;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pcs: Map<any, any>;
    rtcConfig: {
        iceServers: { credential: string; urls: string[]; username: string }[];
    };
    token: string;
    host: string;
    appChannel: string;
    name: string;
    localUsername: string;
    mediaStream: MediaStream;
    socket: WebSocket;

    webRTcPublishPoint: string | null = null;

    video = true;
    noVideoMessage: string = null;
    started = false;
    retryTimeout: number | null = null;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private ss: SourcesService,
        private sharedService: SharedService,
        private navigationService: NavigationService
    ) {
        this.navigationService.setNavVisbility(false);

        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(params => {
            this.sourceId = urlBuilder.decode(params.get("sourceId"));
            if (this.sourceId) {
                this.source = Object.assign({}, this.ss.getCachedSource(null, null, this.sourceId));
                // Check if source found in cache, if not get sources and source
                if (this.sharedService.isEmptyObject(this.source) || !this.source.hasFullDetails) {
                    this.ss
                        .refreshSources(true)
                        .pipe(take(1))
                        .subscribe(async () => {
                            this.source = this.ss.getCachedSource(null, null, this.sourceId);
                            await this.ss.refreshSource(this.source).toPromise();
                            this.source = Object.assign({}, this.ss.getCachedSource(null, null, this.sourceId));
                            //
                            this.setWebRTCThumbnail();
                            this.loading = false;
                        });
                } else this.loading = false;
            }
        });
    }

    ngOnInit() {
        this.setWebRTCThumbnail();
    }

    ngOnDestroy() {
        this.navigationService.setNavVisbility(true);
    }

    noVideo(message?: string) {
        this.video = false;
        this.loadingWebRTC = false;
        this.noVideoMessage = message;
    }

    retryVideo() {
        if (this.retryTimeout) return;

        this.noVideoMessage = null;
        // Remove old video object
        this.removeVideo();
        this.retryTimeout = window.setTimeout(() => {
            this.retryTimeout = null;
            this.setWebRTCThumbnail();
        }, 3000);
    }

    async setWebRTCThumbnail() {
        if (!this.source || this.sharedService.isEmptyObject(this.source)) return;
        const offlineError = (this.source.activeStates || []).find(
            as => as.type === "error" && as.group === "offline" && !as.deescalate_at
        );
        const transcodingError = (this.source.activeStates || []).find(
            as => as.type === "error" && as.group === "transcoding" && !as.deescalate_at
        );
        if (!this.source.is_enabled) {
            // Not Enabled
            this.noVideo("NOT_ENABLED");
        } else if (offlineError) {
            // Offline
            this.noVideo("OFFLINE");
        } else if (transcodingError) {
            // Transcoding Error
            this.noVideo("TRANSCODING_ERROR");
        } else {
            await this.loadWebRTC();
        }
    }

    async loadWebRTC() {
        try {
            if (!this.webRTcPublishPoint) {
                const r = await this.ss.initSourceWebRTC(this.source.id).toPromise();
                this.webRTcPublishPoint = r.webrtc_publish_point ?? null;
            }

            if (this.webRTcPublishPoint) {
                const r = await this.ss.connectSourceWebRTC(this.source.id, this.webRTcPublishPoint).toPromise();
                this.startPlayer(r);
            }
        } catch (e) {
            if (e.error && e.error.error) {
                this.video = false;
                this.loadingWebRTC = false;
                this.noVideoMessage = e.error.error;
            } else this.retryVideo();
            return;
        }
    }

    //
    ///
    //

    startPlayer(d: WebRTCConnectResponse) {
        // eslint-disable-next-line no-console
        // console.log("startPlayer", d);

        this.rtcConfig = {
            iceServers: [d.turn.iceServers]
        };
        this.token = d.token;
        this.host = d.host;
        this.appChannel = d.channel;
        this.name = d.name;
        this.localUsername = d.username;

        this.pcs = new Map();
        this.mediaStream = new MediaStream();

        //
        this.socket = new WebSocket(this.host + "/v2/" + this.token);
        this.socket.addEventListener("message", ev => {
            this.onSocketMessage(ev);
        });
        this.socket.addEventListener("onerror", e => {
            setTimeout(() => {
                this.log("=========== startPlayer on websocket error ================");
                this.retryVideo();
            }, 1000);
        });
        //
    }

    onSocketMessage(evt) {
        // eslint-disable-next-line no-console
        // console.log("onSocketMessage response", this.name, evt);
        const data = JSON.parse(evt.data);
        let pc: RTCPeerConnection;
        // const selectElement = $("#usersSelect")[0];
        let desc;
        let f;
        let sender;
        let candidate: RTCIceCandidate;
        switch (data.m.o) {
            case "peer_connected":
                if (!this.started) {
                    f = data.m.f.split("/");
                    sender = f[f.length - 1];
                    if (sender.startsWith("zixi_peer")) {
                        window.setTimeout(() => {
                            this.log("=============== onSocketMessage peer_connected =================");
                            this.retryVideo();
                        }, 1000);
                    }
                }

                break;
            case "peer_removed":
                f = data.m.f.split("/");
                sender = f[f.length - 1];
                const keys = this.pcs.keys();
                for (const key of keys) {
                    if (key === sender) {
                        window.setTimeout(() => {
                            this.log("=============== onSocketMessage peer_removed =================");
                            this.retryVideo();
                        }, 1000);
                        break;
                    }
                }
                break;
            case "message":
                switch (data.p.msg.type) {
                    case "offer":
                        desc = new RTCSessionDescription(data.p.msg);
                        f = data.m.f.split("/");
                        sender = f[f.length - 1];
                        // eslint-disable-next-line no-console
                        // console.log("this is sender", this.name, sender);
                        pc = this.createNewPeerConnection(sender);
                        pc.setRemoteDescription(desc);
                        //
                        this.pcs.set(sender, {
                            pc,
                            dc: null,
                            s: null,
                            v: null
                        });
                        this.started = true;
                        // eslint-disable-next-line no-console
                        // console.log("here we should have previously printed sender", this.name, this.pcs);
                        // TODO
                        pc.createAnswer().then(d => {
                            this.onCreateAnswer(d, sender);
                        });
                        break;
                    case "answer":
                        desc = new RTCSessionDescription(data.p.msg);
                        f = data.m.f.split("/");
                        sender = f[f.length - 1];
                        // eslint-disable-next-line no-console
                        // console.log("this is sender. line 118 ", this.name, sender);
                        // eslint-disable-next-line no-console
                        // console.log("this are peerconnections. line 119", this.name, this.pcs);
                        this.pcs.get(sender).pc.setRemoteDescription(desc);
                        break;
                    case "candidate":
                        f = data.m.f.split("/");
                        sender = f[f.length - 1];
                        candidate = new RTCIceCandidate(data.p.msg);
                        // eslint-disable-next-line no-console
                        // console.log("this is candidate which we will add", this.name, candidate);
                        // TODO
                        window.setTimeout(() => {
                            this.pcs.get(sender).pc.addIceCandidate(candidate);
                        }, 0);
                }
        }
    }

    onCreateAnswer(d, peer) {
        // eslint-disable-next-line no-console
        // console.log("this is peer", this.name, peer);
        // eslint-disable-next-line no-console
        // console.log("this are peerconnections", this.name, this.pcs);
        this.pcs.get(peer).pc.setLocalDescription(d);

        const pkt = {
            t: "u",
            m: {
                f: this.appChannel + "/" + this.localUsername,
                o: "message",
                t: peer
            },
            p: {
                msg: d
            }
        };
        // eslint-disable-next-line no-console
        // console.log("this is answer which we will send ", this.name, pkt);

        this.socket.send(JSON.stringify(pkt));
    }

    setDataChannelHandlers(dc) {
        dc.onmessage = evt => this.onDataMessage(evt);
        dc.onopen = evt => this.onDataChannelOpen(evt);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onDataChannelOpen(evt) {
        // $("#newMessage")[0].disabled = false;
        // $("#sendButton")[0].disabled = false;
    }

    onDataChannel(evt) {
        // eslint-disable-next-line no-console
        // console.log("this is evt in onDataChannel", this.name, evt);
        const dataChannel = evt.channel;
        // eslint-disable-next-line no-console
        // console.log("this is extracted dataChannel", this.name, dataChannel);
        // eslint-disable-next-line no-console
        // console.log("this are peerconnections", this.name, this.pcs);
        const keys = Object.keys(this.pcs);
        // eslint-disable-next-line no-console
        // console.log("this is keys", this.name, keys);
        let comp;

        for (let i = 0; i < keys.length; i++) {
            comp = this.pcs.get(keys[i]);
            // eslint-disable-next-line no-console
            // console.log("iterating peerconnections", this.name, i);
            if (evt.currentTarget.localDescription.sdp === comp.pc.localDescription.sdp) {
                comp.dc = dataChannel;
                // eslint-disable-next-line no-console
                // console.log("we successfully found matching dataChannel messages should go freely", this.name);
            } else {
                // eslint-disable-next-line no-console
                // console.log("we didn't found matching dataChannel, so messages will not work", this.name);
            }
        }

        this.setDataChannelHandlers(dataChannel);
    }

    onDataMessage(evt) {
        // eslint-disable-next-line no-console
        console.log("inside onDataMessage function", this.name, evt);
        // const messageObj = JSON.parse(evt.data);
        // TODO
        // displayMessage(messageObj.f + " said: " + messageObj.msg);
    }

    createNewPeerConnection(peerName) {
        // eslint-disable-next-line no-console
        // console.log("inside createNewPeerConnection function", this.name);
        // eslint-disable-next-line no-console
        // console.log("this is what we will pass to RTCPeerConnection constructor", this.name, this.rtcConfig);
        const pc = new RTCPeerConnection(this.rtcConfig);
        pc.onicecandidate = evt => {
            // eslint-disable-next-line no-console
            // console.log("onIceCandidate", this.name, evt);
            const remoteUsername = peerName;
            const candidate = evt.candidate;
            if (candidate != null && remoteUsername != null) {
                const cPkt = {
                    type: "candidate",
                    sdpMLineIndex: candidate.sdpMLineIndex,
                    sdpMid: candidate.sdpMid,
                    candidate: candidate.candidate
                };
                const pkt = {
                    t: "u",
                    m: {
                        f: this.appChannel + "/" + this.localUsername,
                        o: "message",
                        t: remoteUsername
                    },
                    p: {
                        msg: cPkt
                    }
                };

                this.socket.send(JSON.stringify(pkt));
            }
        };
        pc.ontrack = evt => {
            // eslint-disable-next-line no-console
            // console.log("this is ontrack event, which we got", this.name, evt);

            if (evt && evt.track) {
                if (evt.track.kind === "audio") {
                    const audioTracks = this.mediaStream.getAudioTracks();
                    if (audioTracks && audioTracks.length > 1) this.mediaStream.removeTrack(audioTracks[0]);
                    //
                    this.mediaStream.addTrack(evt.track);
                } else if (evt.track.kind === "video") {
                    const videoTracks = this.mediaStream.getVideoTracks();
                    // eslint-disable-next-line no-console
                    // console.log("video_tracks", this.name, videoTracks);
                    if (videoTracks && videoTracks.length > 1) {
                        // does removing and adding the new track is sufficient ?
                        this.mediaStream.removeTrack(videoTracks[0]);
                        this.mediaStream.addTrack(evt.track);
                    } else {
                        // eslint-disable-next-line no-console
                        // console.log("ELSE", pc.connectionState);
                        // create video element only once
                        const vid = document.createElement("video");
                        vid.muted = true;

                        vid.controls = true;
                        vid.autoplay = true;

                        (vid as unknown as any).disablePictureInPicture = true; // eslint-disable-line @typescript-eslint/no-explicit-any
                        (vid as unknown as any).disableRemotePlayback = true; // eslint-disable-line @typescript-eslint/no-explicit-any

                        this.mediaStream.addTrack(evt.track);
                        vid.srcObject = this.mediaStream;

                        vid.style.maxWidth = "100%";
                        vid.style.width = "auto";
                        vid.style.height = "100%";

                        document.getElementById("video" + this.name).appendChild(this.decorateVideo(vid));
                        // $("#video" + this.name)[0].appendChild(this.decorateVideo(vid));
                        // eslint-disable-next-line no-console
                        // console.log("we are adding video ", this.name, vid);
                        this.loadingWebRTC = false;
                    }
                }
            }
        };
        pc.oniceconnectionstatechange = () => {
            // eslint-disable-next-line no-console
            // console.log("ICE state: ", this.name, pc.iceConnectionState);
            if (pc.iceConnectionState === "failed") {
                // eslint-disable-next-line no-console
                // console.log("FAILED", this.name);
                this.noVideoMessage = "FAILED";
            }
            if (pc.iceConnectionState === "disconnected") {
                // eslint-disable-next-line no-console
                // console.log("DISCONNECTED", this.name);
                // pc.restartIce(true);
                // TODO: should we show static thumnail at a certain point?
                this.retryVideo();
            }
        };
        pc.ondatachannel = evt => this.onDataChannel(evt);
        return pc;
    }

    removeVideo() {
        const obj = document.getElementById("video" + this.name);
        if (obj) {
            while (obj.firstChild) {
                obj.removeChild(obj.firstChild);
            }
        }
    }

    decorateVideo(vid) {
        vid.oncanplay = () => {
            // eslint-disable-next-line no-console
            // console.log("videoElementMessage Can start playing video");
        };
        vid.onabort = () => {
            // eslint-disable-next-line no-console
            // console.log("videoElementMessage Video load aborted");
        };
        vid.onerror = () => {
            // eslint-disable-next-line no-console
            // console.log("videoElementMessage Error! Something went wrong");
        };
        vid.onprogress = () => {
            // eslint-disable-next-line no-console
            // console.log("videoElementMessage Downloading video");
        };
        vid.onloadstart = () => {
            // eslint-disable-next-line no-console
            // console.log("videoElementMessage onloadstart");
        };
        vid.onprogress = () => {
            // eslint-disable-next-line no-console
            // console.log("videoElementMessage onprogress");
        };
        vid.onsuspend = () => {
            // eslint-disable-next-line no-console
            // console.log("videoElementMessage onsuspend");
        };
        vid.onemptied = () => {
            // eslint-disable-next-line no-console
            // console.log("videoElementMessage onemptied");
        };
        vid.onstalled = () => {
            // eslint-disable-next-line no-console
            // console.log("videoElementMessage onstalled");
        };
        vid.onloadedmetadata = () => {
            // eslint-disable-next-line no-console
            // console.log("videoElementMessage onloadedmetadata");
        };
        vid.onloadeddata = () => {
            // eslint-disable-next-line no-console
            // console.log("videoElementMessage onloadeddata");
        };
        vid.oncanplaythrough = () => {
            // eslint-disable-next-line no-console
            // console.log("videoElementMessage oncanplaythrough");
        };
        vid.onplaying = () => {
            // eslint-disable-next-line no-console
            // console.log("videoElementMessage onplaying");
        };
        vid.onwaiting = () => {
            // eslint-disable-next-line no-console
            // console.log("videoElementMessage onwaiting");
        };
        vid.onseeking = () => {
            // eslint-disable-next-line no-console
            // console.log("videoElementMessage onseeking");
        };
        vid.onseeked = () => {
            // eslint-disable-next-line no-console
            // console.log("videoElementMessage onseeked");
        };
        vid.onended = () => {
            // eslint-disable-next-line no-console
            // console.log("videoElementMessage onended");
        };
        vid.ondurationchange = () => {
            // eslint-disable-next-line no-console
            // console.log("videoElementMessage ondurationchange");
        };
        vid.ontimeupdate = () => {
            // eslint-disable-next-line no-console
            // console.log("videoElementMessage ontimeupdate");
        };
        vid.onplay = () => {
            // eslint-disable-next-line no-console
            // console.log("videoElementMessage onplay");
        };
        vid.onpause = () => {
            // eslint-disable-next-line no-console
            // console.log("videoElementMessage onpause");
        };
        vid.onratechange = () => {
            // eslint-disable-next-line no-console
            // console.log("videoElementMessage onratechange");
        };
        vid.onresize = () => {
            // eslint-disable-next-line no-console
            // console.log("videoElementMessage onresize");
        };
        vid.onvolumechange = () => {
            // eslint-disable-next-line no-console
            // console.log("videoElementMessage onvolumechange");
        };

        return vid;
    }

    log(l: string) {
        // eslint-disable-next-line no-console
        console.log(l);
    }
}
