import { Component, Input } from "@angular/core";
import { NgControl } from "@angular/forms";
import { CustomControlValueAccessor } from "../shared/custom-control-value-accessor/custom-control-value-accessor";

@Component({
    selector: "app-select-generic-field",
    templateUrl: "./select-generic-field.component.html"
})
export class SelectGenericFieldComponent extends CustomControlValueAccessor<string | number[]> {
    @Input() elementId: string;
    @Input() placeHolder: string;
    @Input() items: { [key: string]: string };
    @Input() title: string;
    @Input() valueToBind: string;
    @Input() isParentFormSubmitted: boolean;
    @Input() isRestartRequiredTip: boolean;
    @Input() loading: boolean;
    @Input() multiple = false;
    @Input() clearable?: boolean;
    @Input() circleContent?: string;
    @Input() note?: string;

    disabled = this.ngControl.disabled;

    constructor(public ngControl: NgControl) {
        super(ngControl);
    }

    onChangeValue(value: string | number[]) {
        const matchValue =
            Array.isArray(value) && this.multiple ? value.map(item => item[this.valueToBind]) : value[this.valueToBind];
        this._onChange(matchValue);
    }
}
