<div class="form-group">
    <div class="form-check" [ngClass]="{ 'is-invalid': isInvalid }">
        <input
            [id]="inputId"
            type="checkbox"
            class="form-check-input"
            [(ngModel)]="value"
            (ngModelChange)="notifyControlOnChange(value)"
            (blur)="notifyControlOnTouch($event)"
            [disabled]="isDisabled"
        />
        <zx-forms-field-label [translateKeyLabel]="translateKey.label" [tooltipsConfigs]="tooltipsConfigs" [inputId]="inputId"></zx-forms-field-label>
    </div>
    <small *ngIf="translateKey.bottomLabel">
        {{ translateKey.bottomLabel | translate }}
    </small>
    <zx-forms-field-errors *ngIf="isInvalid" [errors]="ngControl.control.errors"></zx-forms-field-errors>
</div>
