<div class="form-group" [ngClass]="{ 'mb-1': marginBottomZero }">
    <div class="form-check" [ngClass]="{ 'is-invalid': invalid }">
        <input
            [id]="id"
            class="form-check-input"
            type="checkbox"
            [checked]="!!controlledValue"
            [value]="controlledValue"
            [disabled]="disabled"
            (focus)="markAsTouched()"
            (change)="_onChange($event.target.checked)"
        />
        <label class="form-check-label" [for]="id" [ngClass]="{ 'is-invalid': invalid }"
            >{{ label }}<fa-icon *ngIf="required" icon="asterisk" size="xs"></fa-icon
            ><fa-icon
                *ngIf="hasInfoCircle.length"
                class="ms-1"
                icon="info-circle"
                [ngbTooltip]="hasInfoCircle"
                triggers="hover click"
                [closeDelay]="500"
            ></fa-icon>
            <fa-icon
                *ngIf="isRestartRequiredTip"
                class="ms-1"
                icon="redo"
                size="sm"
                [ngbTooltip]="RestartContent"
                triggers="hover click"
                [closeDelay]="500"
            ></fa-icon>
            <ng-template #RestartContent>{{ "RESTART_REQUIRED" | translate }}</ng-template>
        </label>
    </div>
    <small *ngIf="underLabel">
        {{ underLabel }}
    </small>
</div>
