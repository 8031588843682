import { Component, Input, Self } from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { ZxFormsHelper } from "../../utilities/zx-forms-helper";
import { TooltipConfigs } from "../../utilities/zx-forms-tooltips-configs";

@Component({
    selector: "zx-forms-input-checkbox",
    templateUrl: "./zx-forms-input-checkbox.component.html",
    styleUrls: ["./zx-forms-input-checkbox.component.scss"]
})
export class ZxFormsInputCheckboxComponent implements ControlValueAccessor {
    // Required Inputs
    @Input() translateKey: { label: string; bottomLabel?: string };
    @Input() isSubmitted: boolean;
    // Optional Inputs
    @Input() tooltipsConfigs: TooltipConfigs[] = [];
    // Variables - Public
    value: boolean = null;
    isDisabled: boolean;
    inputId = ZxFormsHelper.generateId("checkbox_input");
    notifyControlOnChange: Function;
    notifyControlOnTouch: Function;

    constructor(@Self() public ngControl: NgControl) {
        this.ngControl.valueAccessor = this;
    }

    get isInvalid(): boolean {
        return (
            this.ngControl.control.invalid &&
            (this.ngControl.control.touched || this.ngControl.control.dirty || this.isSubmitted)
        );
    }

    writeValue(value: boolean): void {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.notifyControlOnChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.notifyControlOnTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}
