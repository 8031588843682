import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import moment from "moment";
import { ReplaySubject, firstValueFrom } from "rxjs";
import { Observable, Subscriber } from "rxjs";
import { map, share } from "rxjs/operators";

import { LiveEvent } from "./pages/events/liveevent";
import { APIResponse } from "../../models/shared";
import { Constants } from "../../constants/constants";

import { SharedService } from "../../services/shared.service";
import { AuthService } from "src/app/services/auth.service";
import _ from "lodash";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: "root"
})
export class LiveEventsService {
    liveEvents$: Observable<LiveEvent[]>;
    private liveEventsSubject$: ReplaySubject<LiveEvent[]>;
    private dataStore: {
        liveEvents: LiveEvent[];
    };
    private lastRefresh: number;
    constructor(
        private http: HttpClient,
        private sharedServices: SharedService,
        private authService: AuthService,
        private translate: TranslateService
    ) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            liveEvents: []
        };

        this.lastRefresh = 0;

        this.liveEventsSubject$ = new ReplaySubject(1) as ReplaySubject<LiveEvent[]>;
        this.liveEvents$ = this.liveEventsSubject$.asObservable();
    }

    private prepEvent(liveEvent: LiveEvent) {
        liveEvent._frontData = {
            sortableStatus: "",
            lastRefresh: moment().format()
        };

        this.sharedServices.prepStatusSortFields(liveEvent);
        if (liveEvent.resourceTags)
            liveEvent.resourceTags.sort((a, b) =>
                a.name === b.name ? (a.id < b.id ? -1 : 1) : a.name < b.name ? -1 : 1
            );
        const newLiveEvent = new LiveEvent();
        Object.assign(newLiveEvent, liveEvent);
        return newLiveEvent;
    }

    private updateLiveEventStore(newLiveEvent: LiveEvent, merge: boolean): void {
        newLiveEvent = this.prepEvent(newLiveEvent);
        const currentEventIndex = this.dataStore.liveEvents.findIndex(event => event.id === newLiveEvent.id);
        if (currentEventIndex === -1) {
            this.dataStore.liveEvents.push(newLiveEvent);
            return;
        } else if (merge) {
            const currentEvent = this.dataStore.liveEvents[currentEventIndex];
            Object.assign(currentEvent, newLiveEvent);
        } else {
            this.dataStore.liveEvents[currentEventIndex] = newLiveEvent;
        }
    }

    refreshLiveEvents(force?: boolean): Observable<LiveEvent[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastRefresh <= 60000) return this.liveEvents$;
        this.lastRefresh = _.now();

        const liveEventsSubject$ = this.http
            .get<APIResponse<LiveEvent[]>>(Constants.apiUrl + Constants.apiUrls.liveevents)
            .pipe(share());

        liveEventsSubject$.subscribe(
            data => {
                const events: LiveEvent[] = data.result;
                const dataStoreItems = this.dataStore.liveEvents;
                dataStoreItems.forEach((existingEvent, existingIndex) => {
                    const newIndex = events.findIndex(zec => zec.id === existingEvent.id);
                    if (newIndex === -1) dataStoreItems.splice(existingIndex, 1);
                });
                this.dataStore.liveEvents = dataStoreItems;

                events.forEach(refreshedEvent => this.updateLiveEventStore(refreshedEvent, true));
                this.liveEventsSubject$.next(Object.assign({}, this.dataStore).liveEvents);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_LIVE_EVENTS"), error)
        );
        return liveEventsSubject$.pipe(map(r => r.result));
    }

    refreshLiveEvent(val: number, force?: boolean) {
        const dataStoreItems = this.dataStore.liveEvents;
        if (!force && dataStoreItems && dataStoreItems.length) {
            const event = dataStoreItems.find(e => e.id === val);
            if (event) {
                // Check if last refresh is within last minute
                if (moment().isBefore(moment(event._frontData.lastRefresh).add(1, "minutes"))) {
                    return new Observable((observe: Subscriber<LiveEvent>) => {
                        observe.next(event);
                        observe.complete();
                    });
                }
            }
        }

        const id: number = typeof val === "number" ? val : dataStoreItems.find(event => event.name === val).id;

        const event$ = this.http
            .get<APIResponse<LiveEvent>>(Constants.apiUrl + Constants.apiUrls.liveevents + "/" + id)
            .pipe(share());

        event$.subscribe(
            data => {
                const liveEvent: LiveEvent = data.result;
                liveEvent.hasFullDetails = true;

                this.updateLiveEventStore(liveEvent, false);

                this.liveEventsSubject$.next(Object.assign({}, this.dataStore).liveEvents);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_LIVE_EVENT"), error)
        );
        return event$.pipe(map(e => e.result));
    }

    getCachedLiveEvent(id?: number) {
        const dataStoreItems = this.dataStore.liveEvents;
        if (dataStoreItems && id) return dataStoreItems.find(event => event.id === id);
        return undefined;
    }

    async deleteLiveEvent(liveEvent: LiveEvent) {
        try {
            const result = await this.http
                .delete<APIResponse<number>>(Constants.apiUrl + Constants.apiUrls.liveevents + "/" + `${liveEvent.id}`)
                .toPromise();
            const dataStoreItems = this.dataStore.liveEvents;

            const deletedId: number = result.result;
            const eventIndex = dataStoreItems.findIndex(e => e.id === deletedId);
            if (eventIndex !== -1) dataStoreItems.splice(eventIndex, 1);

            this.dataStore.liveEvents = dataStoreItems;

            this.liveEventsSubject$.next(Object.assign({}, this.dataStore).liveEvents);

            return true;
        } catch (error) {
            return false;
        }
    }

    async addLiveEvent(model: Record<string, unknown>) {
        try {
            const result = await this.http
                .post<APIResponse<LiveEvent>>(Constants.apiUrl + Constants.apiUrls.liveevents, model)
                .toPromise();
            const newLiveEvent: LiveEvent = result.result;

            this.updateLiveEventStore(newLiveEvent, true);

            this.liveEventsSubject$.next(Object.assign({}, this.dataStore).liveEvents);

            return newLiveEvent;
        } catch (error) {
            return false;
        }
    }

    async updateLiveEvent(id: number, model: Record<string, unknown>) {
        try {
            const result = await this.http
                .put<APIResponse<LiveEvent>>(Constants.apiUrl + Constants.apiUrls.liveevents + "/" + id, model)
                .toPromise();
            const updatedEvent: LiveEvent = result.result;

            this.updateLiveEventStore(updatedEvent, true);
            this.liveEventsSubject$.next(Object.assign({}, this.dataStore).liveEvents);

            return updatedEvent;
        } catch (error) {
            return false;
        }
    }

    async IsSlateLocked(liveEvent: LiveEvent) {
        const isLocked = !liveEvent.is_slate_locked;
        try {
            await firstValueFrom(
                this.http.put<{ success: boolean }>(
                    Constants.apiUrl + Constants.apiUrls.liveevents + "/" + liveEvent.id + "/lock",
                    {
                        is_slate_locked: isLocked
                    }
                )
            );
            return true;
        } catch (error) {
            return false;
        }
    }
}
