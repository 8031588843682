<div class="text-end">
    <!-- Back Button -->
    <button
        [id]="inputIds.backButton"
        type="button"
        *ngIf="formAction === FORM_ACTION.CREATE"
        class="btn btn-outline-secondary float-start"
        (click)="location.back()"
        [disabled]="isSaving"
    >
        <fa-icon icon="chevron-left" size="sm"></fa-icon>
        {{ "BACK" | translate }}
    </button>
    <!-- Cancel Button -->
    <button
        [id]="inputIds.cancelButton"
        type="button"
        class="btn btn-outline-secondary"
        (click)="formAction === FORM_ACTION.CREATE ? navigateToListPage() : navigateToDetailsPage()"
        [disabled]="isSaving"
    >
        <fa-icon icon="times"></fa-icon>
        {{ "CANCEL" | translate }}
    </button>
    <!-- Save Button -->
    <button [id]="inputIds.submitButton" type="submit" class="btn btn-primary ms-2" [disabled]="isSaving" [ngClass]="{ 'btn-danger': isInvalid }">
        <fa-icon icon="check" size="sm"></fa-icon>
        {{ "SAVE" | translate }}<span *ngIf="isSaving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
    </button>
</div>
