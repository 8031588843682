import { Component, Input, inject } from "@angular/core";
import { ZxFormsSelectSingleComponent, Item } from "../zx-forms-select-single/zx-forms-select-single.component";
import { EventsManagementService } from "src/app/pages/configuration/events-management/events-management.service";
import { firstValueFrom } from "rxjs";
import { TooltipConfigs } from "../../utilities/zx-forms-tooltips-configs";
import { NgControl } from "@angular/forms";

@Component({
    selector: "zx-forms-select-alerting-profile",
    templateUrl: "../zx-forms-select-single/zx-forms-select-single.component.html"
})
export class ZxFormsSelectAlertingProfileComponent extends ZxFormsSelectSingleComponent {
    // Required Inputs
    @Input() isSubmitted: boolean;
    // Optional Inputs
    @Input() tooltipsConfigs: TooltipConfigs[] = [];
    // Services
    private eventsManagementService = inject(EventsManagementService);

    constructor(public ngControl: NgControl) {
        super(ngControl);
        this.translateKey = { label: "ALERTING_PROFILE", placeholder: "SELECT_ALERTING_PROFILE" };
        this.isClearable = false;
        this.getItems = this.getAlertingProfiles.bind(this);
        this.getDefaultItem = (alertingProfiles: Item[]) => {
            return alertingProfiles.find(p => p.default);
        };
    }

    getAlertingProfiles() {
        return firstValueFrom(this.eventsManagementService.refreshAlertingProfiles());
    }
}
