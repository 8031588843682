import { Component, Input, inject } from "@angular/core";
import { ZxFormsSelectSingleComponent } from "../zx-forms-select-single/zx-forms-select-single.component";
import { NgControl } from "@angular/forms";
import { SharedService } from "src/app/services/shared.service";
import { firstValueFrom } from "rxjs";
import { TooltipConfigs, ZX_FORMS_TOOLTIPS_CONFIGS } from "../../utilities/zx-forms-tooltips-configs";

@Component({
    selector: "zx-forms-select-single-ssh-key",
    templateUrl: "../zx-forms-select-single/zx-forms-select-single.component.html"
})
export class ZxFormsSelectSingleSshKeyComponent extends ZxFormsSelectSingleComponent {
    // Required Inputs
    @Input() isSubmitted: boolean;
    // Optional Inputs
    @Input() tooltipsConfigs: TooltipConfigs[] = [];
    // Services
    private sharedService = inject(SharedService);

    constructor(public ngControl: NgControl) {
        super(ngControl);
        this.translateKey = { label: "SSH_KEY", placeholder: "SELECT_SSH_KEY" };
        this.defaultTooltipsConfigs = [ZX_FORMS_TOOLTIPS_CONFIGS.SSH_KEY_INFO];
        this.isClearable = false;
        this.getItems = this.getSshKeys.bind(this);
    }

    getSshKeys() {
        return firstValueFrom(this.sharedService.getAccessKeys());
    }
}
