<ng-select
    class="form-control"
    name="{{ name }}"
    [disabled]="false"
    [items]="customers"
    [clearable]="false"
    [loading]="loading"
    bindLabel="name"
    bindValue="id"
    placeholder="{{ 'SELECT' | translate }} {{ 'ACCOUNT' | translate }}"
    (change)="modelChanged()"
    [(ngModel)]="model"
    required="{{ required }}"
>
    <ng-template ng-label-tmp let-item="item" *ngIf="!loading"> {{ item.name }} ({{ item.dns_prefix }})</ng-template>
    <ng-template ng-label-tmp let-item="item" *ngIf="loading"> {{ "LOADING" | translate }}... </ng-template>
    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm"> {{ item.name }} ({{ item.dns_prefix }}) </ng-template>
</ng-select>
