<a *ngIf="toggleSlateRef" [class.disabled]="!canToggleSlate" href="javascript:void(0)" title="{{ 'TOGGLE_SLATE' | translate }}" (click)="toggleSlate($event)">
    <fa-icon icon="clapperboard" size="sm"></fa-icon>
    <span class="sr-only">{{ "TOGGLE_SLATE" | translate }}</span>
</a>

<a *ngIf="editRef" [class.disabled]="!canEdit" class="ms-3" href="javascript:void(0)" title="{{ 'EDIT' | translate }}" (click)="edit($event)">
    <fa-icon icon="pencil" size="sm"></fa-icon>
    <span class="sr-only">{{ "EDIT" | translate }}</span>
</a>
<a *ngIf="cloneRef" [class.disabled]="!canClone" class="ms-3" href="javascript:void(0)" title="{{ 'CLONE' | translate }}" (click)="clone($event)">
    <fa-icon [icon]="['far', 'clone']" size="sm"></fa-icon>
    <span class="sr-only">{{ "CLONE" | translate }}</span>
</a>
<a
    *ngIf="enableRef"
    class="ms-3"
    href="javascript:void(0)"
    (click)="enable($event)"
    [class.disabled]="!canEnable"
    [ngClass]="row.is_enabled ? 'text-warning' : 'text-success'"
    title="{{ (row.is_enabled ? 'DISABLE' : 'ENABLE') | translate }}"
>
    <fa-icon icon="power-off" size="sm" *ngIf="!row.is_enabled"></fa-icon><fa-icon icon="ban" size="sm" *ngIf="row.is_enabled"></fa-icon
    ><span class="sr-only">{{ (row.is_enabled ? "DISABLE" : "ENABLE") | translate }}</span>
</a>
<a
    *ngIf="deleteRef"
    [class.disabled]="!canDelete"
    class="text-danger"
    [ngClass]="{ 'ms-3': canEnable || canEdit || canClone }"
    href="javascript:void(0)"
    title="{{ 'DELETE' | translate }}"
    (click)="delete($event)"
>
    <fa-icon icon="trash-alt" size="sm"></fa-icon>
    <span class="sr-only">{{ "DELETE" | translate }}</span>
</a>
