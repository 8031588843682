<div class="form-group">
    <div>
        <zx-forms-field-label [translateKeyLabel]="translateKey.label" [tooltipsConfigs]="tooltipsConfigs" [inputId]="inputId"> </zx-forms-field-label>
        <app-password-strength-icon [password]="value"></app-password-strength-icon>
    </div>
    <div class="input-group">
        <input
            [id]="inputId"
            [type]="isPasswordVisible ? 'text' : 'password'"
            class="form-control"
            [ngClass]="{ 'is-invalid': isInvalid }"
            placeholder="{{ (translateKey.placeholder | translate) || (translateKey.label | translate) }}"
            [(ngModel)]="value"
            (ngModelChange)="notifyControlOnChange(value)"
            (blur)="notifyControlOnTouch($event)"
            [disabled]="isDisabled"
        />
        <button
            *ngIf="hasGeneratePasswordButton"
            class="btn btn-outline-primary"
            [ngClass]="{ 'is-invalid': isInvalid }"
            (click)="generatePassword()"
            type="button"
            [disabled]="isDisabled"
            title="{{ 'GENERATE_A_RANDOM_PASSWORD' | translate }}"
        >
            <fa-icon icon="key"></fa-icon>
        </button>
        <button
            class="btn btn-outline-primary"
            [ngClass]="{ 'is-invalid': isInvalid }"
            (click)="togglePasswordVisibility()"
            type="button"
            [disabled]="isDisabled"
            title="{{ 'SHOW_PASSWORD' | translate }}"
        >
            <fa-icon [icon]="isPasswordVisible ? 'eye-slash' : 'eye'"></fa-icon>
        </button>
        <button
            class="btn btn-outline-primary"
            [ngClass]="{ 'is-invalid': isInvalid }"
            (click)="copyPassword()"
            type="button"
            [disabled]="isDisabled"
            title="{{ 'COPY_PASSWORD' | translate }}"
        >
            <fa-icon icon="clipboard"></fa-icon>
        </button>
    </div>
    <zx-forms-field-errors *ngIf="isInvalid" [errors]="ngControl.control.errors"></zx-forms-field-errors>
</div>
