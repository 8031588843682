import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { TargetsTraceRoutes, TraceRoute } from "src/app/models/shared";
import { Subscription, filter, firstValueFrom, interval } from "rxjs";
import { NavigationStart, Router } from "@angular/router";
import { SharedService } from "src/app/services/shared.service";
import { TargetsService } from "../../targets.service";
import { AnyTarget } from "src/app/pages/channels/channel";

@Component({
    selector: "app-target-traceroute-dialog",
    templateUrl: "./target-traceroute-dialog.component.html"
})
export class TargetTracerouteDialogComponent implements OnDestroy, OnInit {
    target: AnyTarget;
    loading: boolean;
    traceroutes: TargetsTraceRoutes;

    private routeSubscription: Subscription;
    private tracerouteSubscription: Subscription;
    private refreshSubscription: Subscription;

    constructor(
        public activeModal: NgbActiveModal,
        private router: Router,
        private sharedService: SharedService,
        private translate: TranslateService,
        private ts: TargetsService
    ) {
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => {
                // Close modal on navigation event
                this.activeModal.close();
            });
    }

    async ngOnInit() {
        this.loading = true;

        await firstValueFrom(this.ts.refreshTraceroutes(this.target.target));

        this.tracerouteSubscription = this.ts.traceroutes.subscribe(traceroutes => {
            this.traceroutes = traceroutes;

            const oks = traceroutes.result.filter(r => r.success);
            if (oks.length > 0) this.traceroutes.result = [oks[0]];
        });

        this.loading = false;
        // Start  Refresh
        this.startRefresh();
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
        this.tracerouteSubscription.unsubscribe();
        this.stopRefresh();
    }

    urlEncodedTraceroute(s: string) {
        return encodeURIComponent(s.replace(/^[^\n]+\n/, ""));
    }

    startRefresh() {
        this.refreshSubscription = interval(30000).subscribe(() => {
            this.ts.refreshTraceroutes(this.target.target);
        });
    }

    stopRefresh() {
        this.refreshSubscription.unsubscribe();
    }
}
