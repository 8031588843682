import { Component, OnDestroy, OnInit, inject } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ClipboardService } from "ngx-clipboard";
import { Subscription, filter, firstValueFrom } from "rxjs";
import { NavigationStart, Router } from "@angular/router";
import { AnyTarget, SrtTarget, ZixiPullTarget } from "src/app/pages/channels/channel";
import { Constants } from "src/app/constants/constants";
import { UsersService } from "src/app/pages/account-management/users/users.service";
import { TranslateService } from "@ngx-translate/core";
@Component({
    selector: "app-target-config-dialog",
    templateUrl: "./target-config-dialog.component.html"
})
export class TargetConfigDialogComponent implements OnDestroy, OnInit {
    target: AnyTarget;

    isPwVisible = false;
    isKeyVisible = false;

    applicationHost: string;
    constants = Constants;

    pullTarget: ZixiPullTarget;
    srtTarget: SrtTarget;
    pullHost: string;

    private routeSubscription: Subscription;
    public activeModal = inject(NgbActiveModal);
    private router = inject(Router);
    private cbs = inject(ClipboardService);
    private userService = inject(UsersService);
    private translate = inject(TranslateService);

    constructor() {
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => this.activeModal.close());
    }

    async ngOnInit() {
        const user = await firstValueFrom(this.userService.user);
        this.applicationHost = user.application_host;
        this.targetChanged();
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
    }

    private targetChanged() {
        if (this.target.pull) {
            this.pullTarget = this.target.target as ZixiPullTarget;
            this.srtTarget = null;

            this.pullHost = this.pullTarget.disable_redirection
                ? `${this.pullTarget.hostPrefix}.${this.applicationHost}`
                : this.applicationHost;
        }
        if (this.target.srt) {
            this.srtTarget = this.target.target as SrtTarget;
            this.pullTarget = null;

            this.pullHost = `${this.srtTarget.hostPrefix}.${this.applicationHost}`;
        }
    }

    copyString(pw: string) {
        this.cbs.copy(pw);
    }
}
