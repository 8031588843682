import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ClustersService } from "../../../../pages/clusters/clusters.service";
import { RedirectionRouting, TARGET_CIDR } from "@zixi/models";
import { Constants } from "src/app/constants/constants";

enum FROM_OPTIONS {
    CLUSTER = "CLUSTER",
    CIDR = "CIDR"
}

@Component({
    selector: "app-redirection-rule-dialog",
    templateUrl: "./cluster-redirection-rule-dialog.component.html"
})
export class ClusterRedirectionRuleDialogComponent implements OnInit {
    FROM_OPTIONS = FROM_OPTIONS;
    TO_OPTION_REDIRECT_TO_NIC = "REDIRECT_TO_NIC";
    TARGET_CIDR = TARGET_CIDR;
    TO_OPTIONS = [
        {
            name: `${this.translateService.instant("REDIRECT_TO")} ${this.translateService.instant("PUBLIC_IP")}`,
            value: TARGET_CIDR.PUBLIC
        },
        {
            name: `${this.translateService.instant("REDIRECT_TO")} ${this.translateService.instant("PRIVATE_IP")}`,
            value: TARGET_CIDR.PRIVATE
        },
        {
            name: `${this.translateService.instant("REDIRECT_TO")} ${this.translateService.instant("NIC")}`,
            value: this.TO_OPTION_REDIRECT_TO_NIC
        },
        {
            name: this.translateService.instant("BLOCK"),
            value: TARGET_CIDR.BLOCK
        }
    ];
    form = this.formBuilderService.group({
        description: new FormControl<string | null>(null, [Validators.required]),
        from: new FormControl<FROM_OPTIONS>(FROM_OPTIONS.CLUSTER, [Validators.required]),
        client_cidr: new FormControl<string | null>(null, [
            Validators.pattern(Constants.validators.CIDR),
            Validators.required
        ]),
        to: new FormControl<TARGET_CIDR | typeof this.TO_OPTION_REDIRECT_TO_NIC>(TARGET_CIDR.PUBLIC, [
            Validators.required
        ]),
        nic_cidr: new FormControl<string | null>(null, [
            Validators.pattern(Constants.validators.CIDR),
            Validators.required
        ]),
        latency: new FormControl<number | null>(null),
        order: new FormControl<number>(0, [Validators.required])
    });
    clientClusterId: number = null;
    isSaving = false;
    isFormSubmitted = false;
    error: string = null;
    // Inputs
    onSuccessHandler: () => Promise<unknown>;
    broadcasterClusterId: number = null;
    redirectionRouting: RedirectionRouting = null;

    constructor(
        public activeModal: NgbActiveModal,
        public translateService: TranslateService,
        private clustersService: ClustersService,
        private formBuilderService: FormBuilder
    ) {}

    ngOnInit(): void {
        if (this.isEditMode) {
            const isRedirectToNic =
                this.redirectionRouting.target_cidr !== TARGET_CIDR.PUBLIC &&
                this.redirectionRouting.target_cidr !== TARGET_CIDR.PRIVATE &&
                this.redirectionRouting.target_cidr !== TARGET_CIDR.BLOCK;
            this.form.patchValue({
                ...this.redirectionRouting,
                from: this.redirectionRouting.client_cidr ? FROM_OPTIONS.CIDR : FROM_OPTIONS.CLUSTER,
                to: isRedirectToNic ? this.TO_OPTION_REDIRECT_TO_NIC : this.redirectionRouting.target_cidr,
                nic_cidr: isRedirectToNic ? this.redirectionRouting.target_cidr : null
            });
            this.clientClusterId = this.redirectionRouting.client_cluster_id;
        }
    }

    get dialogTitle() {
        return `${this.translateService.instant(this.isEditMode ? "UPDATE" : "CREATE")} ${this.translateService.instant(
            "REDIRECTION_RULE"
        )}`;
    }

    get isEditMode() {
        return Boolean(this.redirectionRouting);
    }

    get isClientClusterInvalid() {
        return this.form.value.from === FROM_OPTIONS.CLUSTER && !this.clientClusterId && this.isFormSubmitted;
    }

    async onSubmit() {
        this.isFormSubmitted = true;

        const isRedirectToNic = this.form.value.to === this.TO_OPTION_REDIRECT_TO_NIC;
        if (isRedirectToNic) {
            this.form.controls.nic_cidr.enable();
        } else {
            this.form.controls.nic_cidr.disable();
        }

        if (this.isClientClusterInvalid) {
            return;
        }

        const isCidrClient = this.form.value.from === FROM_OPTIONS.CIDR;
        if (isCidrClient) {
            this.form.controls.client_cidr.enable();
        } else {
            this.form.controls.client_cidr.disable();
        }

        if (this.form.invalid) {
            return;
        }

        this.isSaving = true;

        try {
            const { description, from, client_cidr, to, nic_cidr, latency, order } = this.form.value;
            const requestBody = {
                description,
                client_cidr: from === FROM_OPTIONS.CIDR ? client_cidr : null,
                target_cidr: to === this.TO_OPTION_REDIRECT_TO_NIC ? nic_cidr : to,
                latency: latency === null ? null : Number(latency),
                order: order === null ? null : Number(order),
                client_cluster_id: from === FROM_OPTIONS.CLUSTER ? this.clientClusterId : null
            };
            if (this.isEditMode) {
                await this.clustersService.updateRedirectionRouting(
                    this.redirectionRouting.id,
                    this.broadcasterClusterId,
                    requestBody
                );
            } else {
                await this.clustersService.createRedirectionRouting(this.broadcasterClusterId, requestBody);
            }
            await this.onSuccessHandler();
            this.activeModal.close();
        } catch (error) {
            this.error = error;
        }
        this.isSaving = false;
    }
}
