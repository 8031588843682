import { Component, Input, inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ZxFormsHelper } from "../../utilities/zx-forms-helper";

@Component({
    selector: "zx-forms-section",
    templateUrl: "./zx-forms-section.component.html"
})
export class ZxFormsSectionComponent {
    // Variables
    sectionId = ZxFormsHelper.generateId("section");
    sectionTitle: string;
    // Services
    private translateService = inject(TranslateService);

    @Input()
    set titleTranslateKeys(translateKeys: string[]) {
        this.sectionTitle = translateKeys.map(translateKey => this.translateService.instant(translateKey)).join(" ");
    }
}
