import { Component, Input, ViewChild, inject, AfterViewInit, ChangeDetectorRef } from "@angular/core";
import { FORM_ACTION } from "../../utilities/zx-forms-helper";
import { TourService } from "ngx-ui-tour-md-menu";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { MatTooltip } from "@angular/material/tooltip";

@Component({
    selector: "zx-forms-title",
    templateUrl: "./zx-forms-title.component.html"
})
export class ZxFormsTitleComponent implements AfterViewInit {
    // Required Inputs
    @Input() formAction: FORM_ACTION;
    @Input() titleTranslateKeys: string[];
    // Optional Inputs
    @Input() tour?: {
        isAutoShow: boolean;
        tooltipTranslateKey: string;
        mixpanelLog?: string;
    };
    // Variables
    FORM_ACTION = FORM_ACTION;
    @ViewChild("tourTooltip", { static: false }) tourTooltip: MatTooltip;
    // Services
    private tourService = inject(TourService);
    private mixpanelService = inject(MixpanelService);
    private changeDetectorRef = inject(ChangeDetectorRef);

    ngAfterViewInit() {
        if (this.tour?.isAutoShow) {
            this.tourTooltip.show();
            this.changeDetectorRef.detectChanges();
        }
    }

    onTourClick() {
        this.tourService.start();
        if (this.tour?.mixpanelLog) {
            this.mixpanelService.track(this.tour.mixpanelLog);
        }
    }
}
