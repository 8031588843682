import { Component, Input, inject } from "@angular/core";
import { Location } from "@angular/common";
import { FORM_ACTION, ZxFormsHelper } from "../../utilities/zx-forms-helper";
import { FormGroup } from "@angular/forms";

@Component({
    selector: "zx-forms-control-bar",
    templateUrl: "./zx-forms-control-bar.component.html"
})
export class ZxFormsControlBarComponent {
    // Required Inputs
    @Input() form: FormGroup;
    @Input() isSaving: boolean;
    @Input() isSubmitted: boolean;
    @Input() formAction: FORM_ACTION;
    @Input() navigateToListPage: Function;
    @Input() navigateToDetailsPage: Function;
    // Variables
    inputIds = {
        submitButton: ZxFormsHelper.generateId("submit-button"),
        cancelButton: ZxFormsHelper.generateId("cancel-button"),
        backButton: ZxFormsHelper.generateId("back-button")
    };
    FORM_ACTION = FORM_ACTION;
    // Services
    public location = inject(Location);

    get isInvalid() {
        return this.form.invalid && (this.form.touched || this.form.dirty || this.isSubmitted);
    }
}
