import { Component, Input, inject } from "@angular/core";
import { Source } from "../../../../models/shared";
import { SourcesService } from "../../sources.service";

@Component({
    selector: "app-source-content-analysis",
    templateUrl: "./source-content-analysis.component.html"
})
export class SourceContentAnalysisComponent {
    @Input() source: Source;
    private ss = inject(SourcesService);

    reset() {
        this.ss.resetTR101(this.source);
    }
}
