<div class="form-group">
    <zx-forms-field-label [translateKeyLabel]="translateKey.label" [tooltipsConfigs]="tooltipsConfigs" [inputId]="inputId"></zx-forms-field-label>
    <div class="input-group">
        <input
            [id]="inputId"
            type="text"
            class="form-control"
            [ngClass]="{ 'is-invalid': isInvalid, disabled: isDisabled }"
            placeholder="{{ (translateKey.placeholder | translate) || (translateKey.label | translate) }}"
            [(ngModel)]="value"
            (ngModelChange)="notifyControlOnChange(value)"
            (blur)="notifyControlOnTouch($event)"
            [disabled]="isDisabled"
            [autocomplete]="autoComplete"
        />
        <button
            *ngIf="hasCopyButton"
            title="{{ 'COPY' | translate }}"
            [disabled]="isDisabled"
            class="btn btn-outline-primary"
            [ngClass]="{ 'is-invalid': isInvalid }"
            type="button"
            (click)="copy()"
        >
            <fa-icon icon="clipboard"></fa-icon>
        </button>
    </div>
    <small *ngIf="translateKey.bottomLabel">
        {{ translateKey.bottomLabel | translate }}
    </small>
    <zx-forms-field-errors *ngIf="isInvalid" [errors]="ngControl.control.errors"></zx-forms-field-errors>
</div>
