import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

// Forms
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { ClipboardModule } from "ngx-clipboard";

// Modules
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { DragulaModule } from "ng2-dragula";
import { FontAwesome } from "./../font-awesome/font-awesome.module";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatSelectModule } from "@angular/material/select";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { MatCardModule } from "@angular/material/card";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatTreeModule } from "@angular/material/tree";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";

// Directives
import { NgbSortableHeader } from "./../../directives/sortable.directive";
import { DuplicateNameDirective } from "./../../directives/duplicate-name.directive";
import { DynamicDuplicateDirective } from "./../../directives/dynamic-duplicate.directive";
import { MustMatchDirective } from "./../../directives/must-match.directive";
import { MinimumSelectedDirective } from "./../../directives/minimum-selected.directive";
import { ClickOutsideDirective } from "./../../directives/click-outside.directive";
import { MinimumValueDirective } from "./../../directives/minimum-value.directive";
import { MaximumValueDirective } from "./../../directives/maximum-value.directive";
import { ScrollSpyDirective } from "./../../directives/scroll-spy.directive";
import { InvertCheckedDirective } from "./../../directives/true-false-value.directive";
import { MaxSelectionDirective } from "./../../directives/max-selection.directive";

// Components
import { LaunchPageFooterComponent } from "./../../components/shared/launch-page-footer/launch-page-footer.component";
import { ZxStatusIconComponent } from "./../../components/shared/zx-status-icon/zx-status-icon.component";
import { ZxStatusFullComponent } from "./../../components/shared/zx-status-full/zx-status-full.component";
import { ReportHistoryActionsColumnComponent } from "src/app/pages/reports/report/report-history/report-history-actions-column/report-history-actions-column.component";
import { ZxTargetTargetsColumnComponent } from "src/app/components/shared/table-list/tables-components/zx-target-targets-column/zx-target-targets-column.component";
import { ZxNetworkObjectsStatusColumnComponent } from "src/app/components/shared/zx-network-objects-status-column/zx-network-objects-status-column.component";
import { ZxMuteComponent } from "./../../components/shared/zx-mute/zx-mute.component";
import { ZxAdvancedNotesComponent } from "src/app/components/shared/zx-advanced-notes/zx-advanced-notes.component";
import { ZxAgentzOsDataComponent } from "src/app/components/shared/zx-agentz-os-data/zx-agentz-os-data.component";
import { NgxJsonViewerModule } from "ngx-json-viewer";
import { ZxHistoryGraphComponent } from "./../../components/shared/zx-history-graph/zx-history-graph.component";
import { ZxFeederComponent } from "./../../components/shared/zx-feeder/zx-feeder.component";
import { ZxZecComponent } from "../../components/shared/zx-zec/zx-zec.component";
import { ZxLiveEventNameComponent } from "../../components/shared/zx-live-event-name/zx-live-event-name.component";
import { ZxReceiverComponent } from "./../../components/shared/zx-receiver/zx-receiver.component";
import { ZxRemoteAccessComponent } from "./../../components/shared/zx-remote-access/zx-remote-access.component";
import { ZxClusterComponent } from "./../../components/shared/zx-cluster/zx-cluster.component";
import { ZxBroadcasterComponent } from "./../../components/shared/zx-broadcaster/zx-broadcaster.component";
import { ZxBroadcasterFilesComponent } from "./../../components/shared/zx-broadcaster-files/broadcaster-files.component";
import { ZxSourceComponent } from "./../../components/shared/zx-source/zx-source.component";
import { ZxMediaConnectSourceComponent } from "./../../components/shared/zx-mediaconnect-source/zx-mediaconnect-source.component";
import { ZxTargetComponent } from "./../../components/shared/zx-target/zx-target.component";
import { ZxTagsListComponent } from "./../../components/shared/zx-tags-list/zx-tags-list.component";
import { ZxAdaptiveComponent } from "./../../components/shared/zx-adaptive/zx-adaptive.component";
import { ZxDeliveryComponent } from "./../../components/shared/zx-delivery/zx-delivery.component";
import { ZxBroadcasterSelectComponent } from "./../../components/shared/zx-broadcaster-select/zx-broadcaster-select.component";
import { ZxZecSelectComponent } from "./../../components/shared/zx-zec-select/zx-zec-select.component";
import { ZxCustomerSelectComponent } from "./../../components/shared/zx-customer-select/zx-customer-select.component";
import { ZxBroadcastersSelectComponent } from "./../../components/shared/zx-broadcasters-select/zx-broadcasters-select.component";
import { ZxSourceSelectComponent } from "./../../components/shared/zx-source-select/zx-source-select.component";
import { ZxAdaptiveChannelSelectComponent } from "./../../components/shared/zx-adaptive-channel-select/zx-adaptive-channel-select.component";
import { ZxDeliveryChannelSelectComponent } from "./../../components/shared/zx-delivery-channel-select/zx-delivery-channel-select.component";
import { ZxFailoverChannelSelectComponent } from "../../components/shared/zx-failover-channel-select/zx-failover-channel-select.component";
import { ZxChannelSelectComponent } from "./../../components/shared/zx-channel-select/zx-channel-select.component";
import { ZxStringSelectionComponent } from "./../../components/shared/zx-string-selection/zx-string-selection.component";
import { SourceSelectionComponent } from "./../../components/shared/source-selection/source-selection.component";
import { SourceFieldSelectionComponent } from "./../../components/shared/source-field-selection/source-field-selection.component";
import { ClusterFieldSelectionComponent } from "./../../components/shared/cluster-field-selection/cluster-field-selection.component";
import { ZxFormBottomButtonsComponent } from "./../../components/shared/zx-form-bottom-buttons/zx-form-bottom-buttons.component";
import { ZxClusterSelectComponent } from "./../../components/shared/zx-cluster-select/zx-cluster-select.component";
import { ZxGroupsSelectComponent } from "./../../components/shared/zx-groups-select/zx-groups-select.component";
import { ZxUsersSelectComponent } from "./../../components/shared/zx-users-select/zx-users-select.component";
import { ZxRolesSelectComponent } from "./../../components/shared/zx-roles-select/zx-roles-select.component";
import { ZxAccessTagSelectComponent } from "./../../components/shared/zx-access-tag-select/zx-access-tag-select.component";
import { ZxAlertingProfileSelectComponent } from "./../../components/shared/zx-alerting-profile-select/zx-alerting-profile-select.component";
import { ZxAccessTagsSelectComponent } from "./../../components/shared/zx-access-tags-select/zx-access-tags-select.component";
import { ZxGridSelectComponent } from "./../../components/shared/zx-grid-select/zx-grid-select.component";
import { ZxSourcesSelectComponent } from "./../../components/shared/zx-sources-select/zx-sources-select.component";
import { ZxClustersSelectComponent } from "./../../components/shared/zx-clusters-select/zx-clusters-select.component";
import { ZxZecsSelectComponent } from "./../../components/shared/zx-zecs-select/zx-zecs-select.component";
import { ZxAdaptiveChannelsSelectComponent } from "./../../components/shared/zx-adaptive-channels-select/zx-adaptive-channels-select.component";
import { ZxDeliveryChannelsSelectComponent } from "./../../components/shared/zx-delivery-channels-select/zx-delivery-channels-select.component";
import { ZxTargetsSelectComponent } from "./../../components/shared/zx-targets-select/zx-targets-select.component";
import { ZxTranscodingProfileSelectComponent } from "./../../components/shared/zx-transcoding-profile-select/zx-transcoding-profile-select.component";
import { ZxPidMappingProfileSelectComponent } from "./../../components/shared/zx-pid-mapping-profile-select/zx-pid-mapping-profile-select.component";
import { ZxDeliveryChannelSourceSelectComponent } from "./../../components/shared/zx-delivery-channel-source-select/zx-delivery-channel-source-select.component";
import { ZxEventsComponent } from "./../../components/shared/zx-events/zx-events.component";
import { ZxScteComponent } from "./../../components/shared/zx-scte/zx-scte.component";
import { LoadingAnimationComponent } from "./../../components/shared/loading-animation/loading-animation.component";
import { SpinnerAnimationComponent } from "./../../components/shared/spinner-animation/spinner-animation.component";
import { SpinnerAnimationInlineComponent } from "./../../components/shared/spinner-animation-inline/spinner-animation-inline.component";
import { PaginationComponent } from "./../../components/shared/pagination/pagination.component";
import { ErrorComponent } from "./../../components/error/error.component";
import { ZxAWSMediaConnectFlowSelectComponent } from "src/app/components/shared/zx-aws-mediaconnect-flow-select/zx-aws-mediaconnect-flow-select.component";
import { ZxMediaConnectSourceSelectComponent } from "src/app/components/shared/zx-mediaconnect-source-select/zx-mediaconnect-source-select.component";
import { ZxMediaConnectSourcesSelectComponent } from "src/app/components/shared/zx-mediaconnect-sources-select/zx-mediaconnect-sources-select.component";
import { ZxMediaConnectFlowSelectComponent } from "src/app/components/shared/zx-mediaconnect-flow-select/zx-mediaconnect-flow-select.component";
import { ZxMediaConnectFlowsSelectComponent } from "src/app/components/shared/zx-mediaconnect-flows-select/zx-mediaconnect-flows-select.component";
import { ZxMediaLiveChannelSelectComponent } from "src/app/components/shared/zx-medialive-channel-select/zx-medialive-channel-select.component";
import { ZxMediaConnectFlowComponent } from "src/app/components/shared/zx-mediaconnect-flow/zx-mediaconnect-flow.component";
import { ZxMediaLiveChannelComponent } from "src/app/components/shared/zx-medialive-channel/zx-medialive-channel.component";
import { ZxFailoverChannelComponent } from "./../../components/shared/zx-failover-channel/zx-failover-channel.component";
import { ZxChannelsSourceComponent } from "../../components/shared/table-list/tables-components/zx-channels-source/zx-channels-source.component";
import { ZxChannelTypeComponent } from "./../../components/shared/zx-channel-type/zx-channel-type.component";
import { ZxIngestClusterSourceComponent } from "./../../components/shared/zx-ingest-cluster-source/zx-ingest-cluster-source.component";
import { ZxNgbHighlightComponent } from "./../../components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { ZxDateTimeDisplayComponent } from "./../../components/shared/zx-date-time-display/zx-date-time-display.component";
import { ZxLinkTextComponent } from "./../../components/shared/zx-link-text/zx-link-text.component";
import { ZxInputSourceColComponent } from "./../../components/shared/zx-input-source-col/zx-input-source-col.component";
import { ZxTr101ColSourceComponent } from "./../../components/shared/zx-tr101-col-source/zx-tr101-col-source.component";
import { ZxNumericColComponent } from "./../../components/shared/zx-numeric-col/zx-numeric-col.component";
import { ZxClusterBroadcastersColComponent } from "./../../components/shared/zx-cluster-broadcasters-col/zx-cluster-broadcasters-col.component";
import { ZxChannelsTargetColumnComponent } from "./../../components/shared/zx-channels-target-column/zx-channels-target-column.component";
import { SourceThumbnailComponent } from "../../pages/sources/source/source-thumbnail/source-thumbnail.component";
import { SourceThumbnailFromServiceComponent } from "src/app/pages/sources/source/source-thumbnail-from-service/source-thumbnail-from-service.component";
import { SourceWebrtcVideoComponent } from "../../pages/sources/source/source-webrtc-video/source-webrtc-video.component";
import { ZxListResizeComponent } from "src/app/components/shared/zx-list-resize/zx-list-resize.component";
import { ZxSplitterComponent } from "src/app/components/shared/zx-splitter/zx-splitter.component";
import { LocationSearchComponent } from "src/app/components/shared/location-search/location-search.component";
import { DiagramComponent } from "src/app/components/shared/diagram/diagram.component";
import { ZxBillingCodesCheckComponent } from "../../components/shared/zx-billing-codes-check/zx-billing-codes-check.component";
import { ActiveStatesComponent } from "src/app/components/active-states/active-states.component";
import { ZxMediaConnectVPCComponent } from "src/app/components/shared/zx-mediaconnect-vpc/zx-mediaconnect-vpc.component";
import { ZxMediaConnectMediaStreamComponent } from "src/app/components/shared/zx-mediaconnect-mediastream/zx-mediaconnect-mediastream.component";
import { ZxTargetChannelColumnComponent } from "src/app/components/shared/table-list/tables-components/zx-target-channel-column/zx-target-channel-column.component";
import { ChannelBitratesStatusColumnComponent } from "src/app/pages/channels/channel/channel-bitrates/channel-bitrates-status-column/channel-bitrates-status-column.component";
import { ChannelBitratesSourceStatusColumnComponent } from "src/app/pages/channels/channel/channel-bitrates/channel-bitrates-source-status-column/channel-bitrates-source-status-column.component";
import { ChannelBitratesProfileColumnComponent } from "src/app/pages/channels/channel/channel-bitrates/channel-bitrates-profile-column/channel-bitrates-profile-column.component";
import { ChannelBitratesSourceColumnComponent } from "src/app/pages/channels/channel/channel-bitrates/channel-bitrates-source-column/channel-bitrates-source-column.component";
import { IconColumnComponent } from "src/app/components/shared/table-list/tables-components/icon-column/icon-column.component";
import { CollapsedFormSectionComponent } from "src/app/components/shared/collapsed-form-section/collapsed-form-section.component";
import { SearchInputComponent } from "src/app/components/shared/search-input/search-input.component";
import { FilterComponent } from "src/app/components/shared/filter/filter.component";
import { ZxEventObjectComponent } from "src/app/components/shared/zx-event-object/zx-event-object.component";
import { MultiviewSourceSelectionComponent } from "src/app/components/shared/multiview-source-select/multiview-source-select.component";
import { MultiplexSourceSelectionComponent } from "src/app/components/shared/multiplex-source-select/multiplex-source-select.component";
import { NotifyingMessageComponent } from "src/app/components/shared/notifying-message/notifying-message.component";
import { SharedResourceListComponent } from "src/app/components/shared/shared-resource-list/shared-resource-list.component";
import { ZxBroadcasterStatusColComponent } from "src/app/pages/clusters/cluster/cluster-broadcasters/broadcaster-status-col/broadcaster-status-col.component";
import { ZxBroadcasterNameColComponent } from "src/app/pages/clusters/cluster/cluster-broadcasters/broadcaster-name-col/broadcaster-name-col.component";
import { ZxBroadcasterIPColComponent } from "src/app/pages/clusters/cluster/cluster-broadcasters/broadcaster-ip-col/broadcaster-ip-col.component";
import { ZxBroadcasterStreamsColComponent } from "src/app/pages/clusters/cluster/cluster-broadcasters/broadcaster-streams-col/broadcaster-streams-col.component";
import { ZxBroadcasterActionsColComponent } from "src/app/pages/clusters/cluster/cluster-broadcasters/broadcaster-actions-col/broadcaster-actions-col.component";
import { ZxIncidentSelectComponent } from "src/app/components/shared/zx-incident-select/zx-incident-select.component";
import { ZxDynamicEventsComponent } from "src/app/components/shared/zx-dynamic-events/zx-dynamic-events.component";
import { ChannelDetailsComponent } from "src/app/pages/channels/channel/channel-details/channel-details.component";
import { ChannelAlertsComponent } from "src/app/pages/channels/channel/channel-alerts/channel-alerts.component";
import { SourceDetailsComponent } from "src/app/pages/sources/source/source-details/source-details.component";
import { BroadcasterDetailsComponent } from "src/app/pages/clusters/broadcaster/broadcaster-details/broadcaster-details.component";
import { ZecDetailsComponent } from "src/app/pages/zecs/zecs/zec/zec-details/zec-details.component";
import { ZxDatePickerComponent } from "src/app/components/shared/zx-date-picker/zx-date-picker.component";
import { TableListComponent } from "src/app/components/shared/table-list/table-list.component";
import { ZxEventsTableRowActionsComponent } from "src/app/components/shared/zx-events-table-row-actions/zx-events-table-row-actions.component";
import { ZxEventsTypesFilterComponent } from "src/app/components/shared/zx-events-types-filter/zx-events-types-filter.component";
import { ZxEditTableRowButtonsComponent } from "src/app/components/shared/zx-edit-table-row-buttons/zx-edit-table-row-buttons.component";
import { ZxChannelTargetsActionButtonsComponent } from "src/app/pages/channels/channel/channel-targets/zx-channel-targets-action-buttons/zx-channel-targets-action-buttons.component";
import { ZxSshKeysTableRowActionsComponent } from "src/app/components/shared/zx-ssh-keys-table-row-actions/zx-ssh-keys-table-row-actions.component";
import { MediaConnectSourceDetailsComponent } from "src/app/pages/sources/mc-source/mc-source-details/mc-source-details.component";
import { TargetDetailsComponent } from "src/app/pages/targets/target/target-details/target-details.component";
import { PasswordInputComponent } from "src/app/components/shared/password-input/password-input.component";
import { PasswordStrengthIconComponent } from "src/app/components/shared/password-strength-icon/password-strength-icon.component";
import { DetailsLocationComponent } from "../../components/details-location/details-location.component";
import { FormTitleComponent } from "../../components/form-title/form-title.component";
import { ZxDateTimePickerComponent } from "src/app/components/shared/zx-date-time-picker/zx-date-time-picker.component";
import { AdvancedSectionFormComponent } from "src/app/components/shared/advanced-section-form/advanced-section-form.component";
import { AnnouncementComponent } from "src/app/components/shared/announcement/announcement.component";
import { SourceContentAnalysisComponent } from "src/app/pages/sources/source/source-content-analysis/source-content-analysis.component";
import { EventsFilterFormComponent } from "../../components/events-filter-form/events-filter-form.component";
import { FormFieldWarperComponent } from "../../components/form-field-warper/form-field-warper.component";
import { IncidentsNameColumnComponent } from "src/app/components/shared/table-list/tables-components/incidents-name-column/incidents-name-column.component";
import { ZxIconComponent } from "src/app/components/shared/zx-icon/zx-icon.component";
import { ZxTableActionsComponent } from "src/app/components/shared/zx-table-actions/zx-table-actions.component";
import { ZxShowCopyLinksComponent } from "src/app/components/shared/zx-show-copy-links/zx-show-copy-links.component";
import { ZxNdiAudioSourcesComponent } from "src/app/components/shared/zx-ndi-audio-sources/zx-ndi-audio-sources.component";
import { ZxBreadcrumbsComponent } from "src/app/components/shared/zx-breadcrumbs/zx-breadcrumbs.component";
import { SummarySourcesColComponent } from "src/app/components/shared/table-list/tables-components/summary-sources-col/summary-sources-col.component";
import { GridObjectsColumnComponent } from "src/app/pages/grids/grid-detail/grid-objects-column/grid-objects-column.component";
import { GridChannelColumnComponent } from "src/app/pages/grids/grid-detail/grid-channel-column/grid-channel-column.component";
import { GridClusterColumnComponent } from "src/app/pages/grids/grid-detail/grid-cluster-column/grid-cluster-column.component";
import { ZxObjectTrackingMainListComponent } from "../../components/shared/zx-object-tracking/main-list/zx-object-tracking-main-list.component";
import { ZxObjectTrackingSubListComponent } from "../../components/shared/zx-object-tracking/sub-list/zx-object-tracking-sub-list.component";
import { FailoverRulesComponent } from "src/app/pages/channels/channel-form-failover-channel/failover-rules/failover-rules.component";
import { FailoverErrorConcealmentFormComponent } from "src/app/pages/channels/channel-form-failover-channel/error-concealment/error-concealment.component";
import { GenericDetailComponent } from "src/app/components/generic-detail/generic-detail.component";
import { BroadcasterFieldSelectionComponent } from "src/app/components/shared/broadcaster-field-selection/broadcaster-field-selection.component";
import { TargetBroadcasterSelectionComponent } from "src/app/components/shared/target-broadcaster-selection/target-broadcaster-selection.component";

// New Forms (zx-forms) - Fields
import { ZxFormsTagsSelectComponent } from "../../components/forms-new/fields/zx-forms-tags-select/zx-forms-tags-select.component";
import { ZxFormsTextInputComponent } from "../../components/forms-new/fields/zx-forms-text-input/zx-forms-text-input.component";
import { ZxFormsInputPasswordComponent } from "../../components/forms-new/fields/zx-forms-input-password/zx-forms-input-password.component";
import { ZxFormsSelectSingleComponent } from "../../components/forms-new/fields/zx-forms-select-single/zx-forms-select-single.component";
import { ZxFormsSelectAlertingProfileComponent } from "../../components/forms-new/fields/zx-forms-select-alerting-profile/zx-forms-select-alerting-profile.component";
import { ZxFormsInputLocationComponent } from "../../components/forms-new/fields/zx-forms-input-location/zx-forms-input-location.component";
import { ZxFormsSelectSingleSshKeyComponent } from "../../components/forms-new/fields/zx-forms-select-single-ssh-key/zx-forms-select-single-ssh-key.component";
import { ZxFormsInputCheckboxComponent } from "../../components/forms-new/fields/zx-forms-input-checkbox/zx-forms-input-checkbox.component";
// New Forms (zx-forms) - Fields Assets
import { ZxFormsFieldLabelComponent } from "../../components/forms-new/fields-assets/zx-forms-field-label/zx-forms-field-label.component";
import { ZxFormsFieldErrorsComponent } from "../../components/forms-new/fields-assets/zx-forms-field-errors/zx-forms-field-errors.component";
// New Forms (zx-forms) - Forms Assets
import { ZxFormsTitleComponent } from "../../components/forms-new/forms-assets/zx-forms-title/zx-forms-title.component";
import { ZxFormsContentWrapperComponent } from "../../components/forms-new/forms-assets/zx-forms-content-wrapper/zx-forms-content-wrapper.component";
import { ZxFormsControlBarComponent } from "../../components/forms-new/forms-assets/zx-forms-control-bar/zx-forms-control-bar.component";
import { ZxFormsSectionComponent } from "../../components/forms-new/forms-assets/zx-forms-section/zx-forms-section.component";

// Charts
import { ChartContainerComponent } from "src/app/components/charts/chart-container/chart-container.component";
import { BitratesChartComponent } from "src/app/components/charts/bitrates-chart/bitrates-chart.component";

// Pipes
import { FirstLetterPipe } from "./../../pipes/first-letter.pipe";
import { StatusClassPipe } from "./../../pipes/status-class.pipe";
import { StatusTextPipe } from "./../../pipes/status-text.pipe";
import { TargetStatusTextPipe } from "./../../pipes/target-status-text.pipe";
import { StatusIconPipe } from "./../../pipes/status-icon.pipe";
import { OrderByPipe } from "src/app/pipes/order-by.pipe";
import { BitratePipe } from "./../../pipes/bitrate.pipe";
import { SafeUrlPipe } from "./../../pipes/safe-url.pipe";
import { DesnakePipe } from "./../../pipes/desnake.pipe";
import { ObjectTypeLetterPipe } from "./../../pipes/object-type-letter.pipe";
import { BytesPipe } from "./../../pipes/bytes.pipe";
import { NamesPipe, BracketsPipe } from "./../../pipes/names.pipe";
import { VersionPipe } from "./../../pipes/version.pipe";
import { StatePipe } from "./../../pipes/state.pipe";
import { ErrorMessagePipe } from "./../../pipes/error-message.pipe";
import { UptimePipe } from "./../../pipes/uptime.pipe";
import { FilterPipe } from "./../../pipes/filter.pipe";
import { TimestampPipe } from "./../../pipes/timestamp.pipe";
import { FeederInputPipe } from "./../../pipes/feeder-input.pipe";
import { FeederInputTypePipe } from "./../../pipes/feeder-input-type.pipe";
import { FeederInputSourcePipe } from "./../../pipes/feeder-input-source.pipe";
import { FeederInputStatusPipe } from "./../../pipes/feeder-input-status.pipe";
import { BroadcasterInputPipe, BroadcasterInputPartialPipe } from "./../../pipes/broadcaster-input.pipe";
import { ZecInputPipe } from "./../../pipes/zec-input.pipe";
import { ZecInputStatusPipe } from "./../../pipes/zec-input-status.pipe";
import { BroadcasterInputStatusPipe } from "./../../pipes/broadcaster-input-status.pipe";
import { BroadcasterOutputPipe } from "./../../pipes/broadcaster-output.pipe";
import { BroadcasterOutputErrorPipe } from "./../../pipes/broadcaster-output-error.pipe";
import { BroadcasterOutputStatusPipe } from "./../../pipes/broadcaster-output-status.pipe";
import { ReceiverOutputPipe } from "./../../pipes/receiver-output.pipe";
import { ReceiverOutputTypePipe } from "./../../pipes/receiver-output-type.pipe";
import { ReceiverOutputStatusPipe } from "./../../pipes/receiver-output-status.pipe";
import { ReceiverOutputTargetPipe } from "./../../pipes/receiver-output-target.pipe";
import { TimezonePipe } from "./../../pipes/timezone.pipe";
import { SingularPipe } from "./../../pipes/singular.pipe";
import { MultiLevelFilter } from "./../../pipes/multilevelFilter.pipe";
import { NetworkPipe } from "./../../pipes/network.pipe";
import { DeescalatePipe } from "./../../pipes/deescalate.pipe";
import { UIdPipe } from "./../../pipes/uid.pipe";
import { LocationDisplayNamePipe } from "../../pipes/location-display-name.pipe";
import { MapPipe } from "../../pipes/map.pipe";
import { InputGenericFieldComponent } from "src/app/components/input-generic-field/input-generic-field.component";
import { RadioInputComponent } from "src/app/components/radio-input/radio-input.component";
import { SelectGenericFieldComponent } from "src/app/components/select-generic-field/select-generic-field.component";
import { RolesPermissionsColumnComponent } from "src/app/components/shared/table-list/tables-components/roles-permissions-column/roles-permissions-column.component";
import { SourceSourcesNameColumnComponent } from "src/app/pages/sources/source/source-sources/source-sources-name-column/source-sources-name-column.component";
import { ZxSourceSourcesActionButtonsComponent } from "src/app/pages/sources/source/source-sources/zx-source-sources-action-buttons/zx-source-sources-action-buttons.component";
import { GenericNgbTitleComponent } from "src/app/components/generic-ngb-title/generic-ngb-title.component";
import { SourceSourcesComponent } from "src/app/pages/sources/source/source-sources/source-sources.component";
import { FeederInputNameColumnComponent } from "src/app/pages/zecs/zecs/zec/zec-inputs/feeder-input-name-column/feeder-input-name-column.component";
import { ZecInputNameColumnComponent } from "src/app/pages/zecs/zecs/zec/zec-inputs/zec-input-name-column/zec-input-name-column.component";
import { ReceiverOutputNameColumnComponent } from "src/app/pages/zecs/zecs/zec/zec-outputs/receiver-output-name-column/receiver-output-name-column.component";
import { ZecOutputNameColumnComponent } from "src/app/pages/zecs/zecs/zec/zec-outputs/zec-output-name-column/zec-output-name-column.component";
import { ActiveObjectsTargetBXColumnComponent } from "src/app/pages/clusters/broadcaster/broadcaster-active-objects/active-objects-targetbx-column/active-objects-targetbx-column.component";
import { IoServerStatusColumnComponent } from "src/app/pages/io-servers/io-server-list/io-server-status-column/io-server-status-column.component";
import { IoServerTunnelsColumnComponent } from "src/app/pages/io-servers/io-server-list/io-server-tunnels-column/io-server-tunnels-column.component";
import { CheckboxFieldComponent } from "src/app/components/checkbox-field/checkbox-field.component";
import { FormSectionTitleComponent } from "../../components/form-section-title/form-section-title.component";
import { FormWrapperComponent } from "../../components/form-wrapper/form-wrapper.component";
import { TableMaterialWrapperComponent } from "../../components/table-material-wrapper/table-material-wrapper.component";
import { ZxActionButtonsComponent } from "../../components/shared/zx-action-buttons/zx-action-buttons.component";
import { InputErrorsIndicatorComponent } from "../../components/input-errors-indicator/input-errors-indicator.component";
import { ZmidDirective } from "../../directives/zmid.directive";
import { RestartRequiredIconComponent } from "../../components/restart-required-icon/restart-required-icon.component";
import { ResizeColumnDirective } from "../../components/shared/table-list/resize-column.directive";
import { ContainerDataDirective } from "../../components/shared/table-list/container-data.directive";
import { TourMatMenuModule } from "ngx-ui-tour-md-menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ZxUITourComponent } from "src/app/components/shared/zx-ui-tour/zx-ui-tour.component";
import { DialogObjectComponent } from "src/app/components/shared/modals/multiple-confirm-dialog/dialog-object/dialog-object.component";
import { DetailsSectionComponent } from "src/app/components/shared/new-details-page/details-section/details-section.component";
import { DetailsPageHeaderComponent } from "src/app/components/shared/new-details-page/details-page-header/details-page-header.component";
import { LayoutButtonsComponent } from "src/app/components/shared/new-details-page/layout-buttons/layout-buttons.component";
import { PinButtonComponent } from "src/app/components/shared/new-details-page/pin-button/pin-button.component";
import { WidgetSectionComponent } from "src/app/components/shared/new-details-page/widget-section/widget-section.component";
import { NewDetailsComponent } from "src/app/components/shared/new-details-page/details-section/new-details/new-details.component";
import { WidgetSectionHeaderComponent } from "src/app/components/shared/new-details-page/widget-section/widget-section-header/widget-section-header.component";
import { DetailsPageHeaderActionButtonsComponent } from "src/app/components/shared/new-details-page/details-page-header/details-page-header-action-buttons/details-page-header-action-buttons.component";
import { DetailsPageHeaderDropDownComponent } from "src/app/components/shared/new-details-page/details-page-header/details-page-header-drop-down/details-page-header-drop-down.component";
import { ListInsightsComponent } from "src/app/components/insights/list-insights.component";
import { ZxMultiviewerSelectComponent } from "src/app/components/shared/zx-multiviewer-select/zx-multiviewer-select.component";
import { ObjectIncidentListComponent } from "src/app/components/shared/object-incidents/object-incident-list.component";
import { ChromaMapperPipe } from "../../pipes/chroma-mapper.pipe";
import { GridstackModule } from "gridstack/dist/angular";
import { ZxNetworkComponent } from "../../components/shared/zx-network/zx-network.component";
import { ReactFlowDiagramComponent } from "src/app/components/shared/react-flow-diagram/react-flow-diagram.component";
import { MinuteSecondsPipe } from "src/app/pipes/minutes-seconds.pipe";
import { EditAlertingRuleComponent } from "src/app/pages/configuration/events-management/edit-alerting-rule/edit-alerting-rule.component";
import { ViewAlertingRuleComponent } from "src/app/pages/configuration/events-management/view-alerting-rule/view-alerting-rule.component";
import { CopyButtonComponent } from "src/app/components/shared/copy-button/copy-button.component";
import { SourceThumbnailInfoComponent } from "src/app/pages/sources/source/source-thumbnail/source-thumbnail-info/source-thumbnail-info.component";
import { ReactComponentWrapperDirective } from "src/app/directives/react-component-wrapper.directive";
import { MiniFormWrapperComponent } from "src/app/components/mini-form-wrapper/mini-form-wrapper.component";
import { TargetParentComponent } from "src/app/pages/targets/target/target-parent/target-parent.component";
//
@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        DragulaModule,
        FontAwesome,
        NgbModule,
        FormsModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
        NgSelectModule,
        ClipboardModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatCheckboxModule,
        DragDropModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatMenuModule,
        MatIconModule,
        MatCardModule,
        MatButtonToggleModule,
        TourMatMenuModule,
        MatTooltipModule,
        MatTreeModule,
        NgxJsonViewerModule,
        GridstackModule
    ],
    exports: [
        ContainerDataDirective,
        CommonModule,
        RouterModule,
        TranslateModule,
        ClipboardModule,
        ReactiveFormsModule,
        NgSelectModule,
        NgbSortableHeader,
        DuplicateNameDirective,
        DynamicDuplicateDirective,
        MustMatchDirective,
        ReactComponentWrapperDirective,
        MinimumSelectedDirective,
        ClickOutsideDirective,
        MinimumValueDirective,
        MaximumValueDirective,
        ScrollSpyDirective,
        InvertCheckedDirective,
        MaxSelectionDirective,
        ZmidDirective,
        ResizeColumnDirective,
        LaunchPageFooterComponent,
        InputErrorsIndicatorComponent,
        FormSectionTitleComponent,
        RestartRequiredIconComponent,
        FormWrapperComponent,
        MiniFormWrapperComponent,
        TableMaterialWrapperComponent,
        ZxActionButtonsComponent,
        DetailsLocationComponent,
        FormTitleComponent,
        InputGenericFieldComponent,
        CopyButtonComponent,
        ListInsightsComponent,
        SelectGenericFieldComponent,
        RadioInputComponent,
        CheckboxFieldComponent,
        ZxStatusIconComponent,
        ZxStatusFullComponent,
        ZxTargetTargetsColumnComponent,
        ReportHistoryActionsColumnComponent,
        ZxNetworkObjectsStatusColumnComponent,
        ZxMuteComponent,
        ZxAdvancedNotesComponent,
        ZxAgentzOsDataComponent,
        ZxHistoryGraphComponent,
        ZxFeederComponent,
        ZxZecComponent,
        ZxLiveEventNameComponent,
        ZxReceiverComponent,
        ZxRemoteAccessComponent,
        ZxClusterComponent,
        ZxBroadcasterComponent,
        ZxBroadcasterFilesComponent,
        ZxSourceComponent,
        ZxMediaConnectSourceComponent,
        ZxTargetComponent,
        ZxTagsListComponent,
        ZxAdaptiveComponent,
        ZxNetworkComponent,
        ZxMediaConnectFlowComponent,
        ZxMediaLiveChannelComponent,
        ZxFailoverChannelComponent,
        ZxChannelsSourceComponent,
        ZxChannelTypeComponent,
        ZxIngestClusterSourceComponent,
        ZxNgbHighlightComponent,
        ZxDateTimeDisplayComponent,
        ZxLinkTextComponent,
        ZxInputSourceColComponent,
        ZxEventObjectComponent,
        ZxBroadcasterStatusColComponent,
        ZxBroadcasterStreamsColComponent,
        ZxBroadcasterIPColComponent,
        ZxBroadcasterNameColComponent,
        ZxBroadcasterActionsColComponent,
        ZxNumericColComponent,
        ZxClusterBroadcastersColComponent,
        ZxTr101ColSourceComponent,
        ZxChannelsTargetColumnComponent,
        ZxDeliveryComponent,
        ZxMultiviewerSelectComponent,
        ZxBroadcasterSelectComponent,
        ZxZecSelectComponent,
        ZxCustomerSelectComponent,
        ZxBroadcastersSelectComponent,
        ZxSourceSelectComponent,
        ZxMediaConnectSourceSelectComponent,
        ZxMediaConnectSourcesSelectComponent,
        ZxAdaptiveChannelSelectComponent,
        ZxDeliveryChannelSelectComponent,
        ZxFailoverChannelSelectComponent,
        ZxChannelSelectComponent,
        ZxStringSelectionComponent,
        SourceSelectionComponent,
        SourceFieldSelectionComponent,
        ClusterFieldSelectionComponent,
        ZxFormBottomButtonsComponent,
        ZxAWSMediaConnectFlowSelectComponent,
        ZxMediaConnectFlowSelectComponent,
        ZxMediaConnectFlowsSelectComponent,
        ZxMediaLiveChannelSelectComponent,
        ZxClusterSelectComponent,
        ZxGroupsSelectComponent,
        ZxUsersSelectComponent,
        ZxRolesSelectComponent,
        ZxAccessTagSelectComponent,
        ZxAlertingProfileSelectComponent,
        ZxAccessTagsSelectComponent,
        ZxFormsFieldLabelComponent,
        ZxFormsFieldErrorsComponent,
        ZxFormsTagsSelectComponent,
        ZxFormsSelectSingleComponent,
        ZxFormsSelectAlertingProfileComponent,
        ZxFormsSelectSingleSshKeyComponent,
        ZxFormsInputLocationComponent,
        ZxFormsTextInputComponent,
        ZxFormsInputPasswordComponent,
        ZxFormsInputCheckboxComponent,
        ZxFormsTitleComponent,
        ZxFormsSectionComponent,
        ZxFormsContentWrapperComponent,
        ZxFormsControlBarComponent,
        ZxGridSelectComponent,
        ZxSourcesSelectComponent,
        ZxClustersSelectComponent,
        ZxZecsSelectComponent,
        ZxAdaptiveChannelsSelectComponent,
        ZxDeliveryChannelsSelectComponent,
        ZxTargetsSelectComponent,
        ZxTranscodingProfileSelectComponent,
        ZxPidMappingProfileSelectComponent,
        TargetParentComponent,
        ZxEventsComponent,
        ZxScteComponent,
        LoadingAnimationComponent,
        SpinnerAnimationComponent,
        SpinnerAnimationInlineComponent,
        PaginationComponent,
        ErrorComponent,
        SourceThumbnailComponent,
        SourceThumbnailFromServiceComponent,
        SourceThumbnailInfoComponent,
        SourceContentAnalysisComponent,
        EventsFilterFormComponent,
        SourceWebrtcVideoComponent,
        ZxListResizeComponent,
        ZxSplitterComponent,
        LocationSearchComponent,
        DiagramComponent,
        ReactFlowDiagramComponent,
        ZxBillingCodesCheckComponent,
        ActiveStatesComponent,
        ZxDeliveryChannelSourceSelectComponent,
        ZxMediaConnectVPCComponent,
        ZxMediaConnectMediaStreamComponent,
        ZxDynamicEventsComponent,
        ChannelDetailsComponent,
        ChannelAlertsComponent,
        BroadcasterDetailsComponent,
        ZecDetailsComponent,
        ZxDatePickerComponent,
        TableListComponent,
        ZxEditTableRowButtonsComponent,
        ZxChannelTargetsActionButtonsComponent,
        SourceSourcesComponent,
        TargetDetailsComponent,
        SourceDetailsComponent,
        MediaConnectSourceDetailsComponent,
        ZxIncidentSelectComponent,
        FormFieldWarperComponent,
        ZxTargetChannelColumnComponent,
        IncidentsNameColumnComponent,
        RolesPermissionsColumnComponent,
        SourceSourcesNameColumnComponent,
        FeederInputNameColumnComponent,
        ZecInputNameColumnComponent,
        IoServerStatusColumnComponent,
        IoServerTunnelsColumnComponent,
        ReceiverOutputNameColumnComponent,
        ZecOutputNameColumnComponent,
        ZxSourceSourcesActionButtonsComponent,
        ChannelBitratesStatusColumnComponent,
        ChannelBitratesSourceStatusColumnComponent,
        ChannelBitratesProfileColumnComponent,
        ChannelBitratesSourceColumnComponent,
        IconColumnComponent,
        CollapsedFormSectionComponent,
        ZxNdiAudioSourcesComponent,
        SearchInputComponent,
        GenericNgbTitleComponent,
        MultiviewSourceSelectionComponent,
        MultiplexSourceSelectionComponent,
        FilterComponent,
        NotifyingMessageComponent,
        ObjectIncidentListComponent,
        SharedResourceListComponent,
        PasswordInputComponent,
        PasswordStrengthIconComponent,
        ZxDateTimePickerComponent,
        AdvancedSectionFormComponent,
        AnnouncementComponent,
        ZxIconComponent,
        ZxEventsTableRowActionsComponent,
        ZxEventsTypesFilterComponent,
        ZxSshKeysTableRowActionsComponent,
        ZxTableActionsComponent,
        ZxShowCopyLinksComponent,
        ZxBreadcrumbsComponent,
        SummarySourcesColComponent,
        GridObjectsColumnComponent,
        GridChannelColumnComponent,
        GridClusterColumnComponent,
        ChartContainerComponent,
        BitratesChartComponent,
        ZxUITourComponent,
        DialogObjectComponent,
        ActiveObjectsTargetBXColumnComponent,
        DetailsSectionComponent,
        DetailsPageHeaderComponent,
        LayoutButtonsComponent,
        PinButtonComponent,
        WidgetSectionComponent,
        NewDetailsComponent,
        WidgetSectionHeaderComponent,
        DetailsPageHeaderActionButtonsComponent,
        DetailsPageHeaderDropDownComponent,
        ZxObjectTrackingMainListComponent,
        ZxObjectTrackingSubListComponent,
        EditAlertingRuleComponent,
        ViewAlertingRuleComponent,
        FailoverRulesComponent,
        FailoverErrorConcealmentFormComponent,
        GenericDetailComponent,
        BroadcasterFieldSelectionComponent,
        TargetBroadcasterSelectionComponent,
        // Pipes
        FirstLetterPipe,
        StatusClassPipe,
        StatusTextPipe,
        TargetStatusTextPipe,
        StatusIconPipe,
        OrderByPipe,
        BitratePipe,
        SafeUrlPipe,
        DesnakePipe,
        ObjectTypeLetterPipe,
        BytesPipe,
        NamesPipe,
        BracketsPipe,
        VersionPipe,
        StatePipe,
        ErrorMessagePipe,
        UptimePipe,
        FilterPipe,
        TimestampPipe,
        FeederInputPipe,
        FeederInputTypePipe,
        FeederInputSourcePipe,
        FeederInputStatusPipe,
        BroadcasterInputPipe,
        BroadcasterInputPartialPipe,
        BroadcasterInputStatusPipe,
        BroadcasterOutputPipe,
        BroadcasterOutputErrorPipe,
        BroadcasterOutputStatusPipe,
        ReceiverOutputPipe,
        ReceiverOutputTypePipe,
        ReceiverOutputStatusPipe,
        ReceiverOutputTargetPipe,
        TimezonePipe,
        SingularPipe,
        MultiLevelFilter,
        NetworkPipe,
        DeescalatePipe,
        UIdPipe,
        ChromaMapperPipe,
        LocationDisplayNamePipe,
        MapPipe,
        MatSlideToggleModule,
        MatButtonToggleModule,
        ZecInputPipe,
        ZecInputStatusPipe,
        MinuteSecondsPipe
    ],
    declarations: [
        ContainerDataDirective,
        NgbSortableHeader,
        DuplicateNameDirective,
        DynamicDuplicateDirective,
        MustMatchDirective,
        ReactComponentWrapperDirective,
        MinimumSelectedDirective,
        ClickOutsideDirective,
        MinimumValueDirective,
        MaximumValueDirective,
        ScrollSpyDirective,
        InvertCheckedDirective,
        MaxSelectionDirective,
        ZmidDirective,
        ResizeColumnDirective,
        LaunchPageFooterComponent,
        InputErrorsIndicatorComponent,
        FormSectionTitleComponent,
        RestartRequiredIconComponent,
        FormWrapperComponent,
        MiniFormWrapperComponent,
        TableMaterialWrapperComponent,
        ZxActionButtonsComponent,
        DetailsLocationComponent,
        FormTitleComponent,
        InputGenericFieldComponent,
        CopyButtonComponent,
        ListInsightsComponent,
        SelectGenericFieldComponent,
        RadioInputComponent,
        CheckboxFieldComponent,
        ZxStatusIconComponent,
        ZxStatusFullComponent,
        ZxTargetTargetsColumnComponent,
        ReportHistoryActionsColumnComponent,
        ZxNetworkObjectsStatusColumnComponent,
        ZxMuteComponent,
        ZxAdvancedNotesComponent,
        ZxAgentzOsDataComponent,
        ZxHistoryGraphComponent,
        ZxFeederComponent,
        ZxZecComponent,
        ZxLiveEventNameComponent,
        ZxReceiverComponent,
        ZxRemoteAccessComponent,
        ZxClusterComponent,
        ZxBroadcasterComponent,
        ZxBroadcasterFilesComponent,
        ZxSourceComponent,
        ZxMediaConnectSourceComponent,
        ZxTargetComponent,
        ZxTagsListComponent,
        ZxAdaptiveComponent,
        ZxNetworkComponent,
        ZxMediaConnectFlowComponent,
        ZxMediaLiveChannelComponent,
        ZxFailoverChannelComponent,
        ZxChannelsSourceComponent,
        ZxChannelTypeComponent,
        ZxIngestClusterSourceComponent,
        ZxNgbHighlightComponent,
        ZxDateTimeDisplayComponent,
        ZxLinkTextComponent,
        ZxInputSourceColComponent,
        ZxEventObjectComponent,
        ZxBroadcasterStatusColComponent,
        ZxBroadcasterStreamsColComponent,
        ZxBroadcasterIPColComponent,
        ZxBroadcasterNameColComponent,
        ZxBroadcasterActionsColComponent,
        ZxNumericColComponent,
        ZxClusterBroadcastersColComponent,
        ZxTr101ColSourceComponent,
        ZxChannelsTargetColumnComponent,
        ZxDeliveryComponent,
        ZxMultiviewerSelectComponent,
        ZxBroadcasterSelectComponent,
        ZxZecSelectComponent,
        ZxCustomerSelectComponent,
        ZxBroadcastersSelectComponent,
        ZxSourceSelectComponent,
        ZxMediaConnectSourceSelectComponent,
        ZxMediaConnectSourcesSelectComponent,
        ZxAdaptiveChannelSelectComponent,
        ZxDeliveryChannelSelectComponent,
        ZxFailoverChannelSelectComponent,
        ZxChannelSelectComponent,
        ZxStringSelectionComponent,
        SourceSelectionComponent,
        SourceFieldSelectionComponent,
        ClusterFieldSelectionComponent,
        ZxFormBottomButtonsComponent,
        ZxAWSMediaConnectFlowSelectComponent,
        ZxMediaConnectFlowSelectComponent,
        ZxMediaLiveChannelSelectComponent,
        ZxMediaConnectFlowsSelectComponent,
        ZxClusterSelectComponent,
        ZxGroupsSelectComponent,
        ZxUsersSelectComponent,
        ZxRolesSelectComponent,
        ZxAccessTagSelectComponent,
        ZxAlertingProfileSelectComponent,
        ZxAccessTagsSelectComponent,
        ZxFormsFieldLabelComponent,
        ZxFormsFieldErrorsComponent,
        ZxFormsTagsSelectComponent,
        ZxFormsSelectSingleComponent,
        ZxFormsSelectAlertingProfileComponent,
        ZxFormsSelectSingleSshKeyComponent,
        ZxFormsInputLocationComponent,
        ZxFormsTextInputComponent,
        ZxFormsInputPasswordComponent,
        ZxFormsInputCheckboxComponent,
        ZxFormsTitleComponent,
        ZxFormsSectionComponent,
        ZxFormsContentWrapperComponent,
        ZxFormsControlBarComponent,
        ZxGridSelectComponent,
        ZxSourcesSelectComponent,
        ZxClustersSelectComponent,
        ZxZecsSelectComponent,
        ZxAdaptiveChannelsSelectComponent,
        ZxDeliveryChannelsSelectComponent,
        ZxTargetsSelectComponent,
        ZxTranscodingProfileSelectComponent,
        ZxPidMappingProfileSelectComponent,
        TargetParentComponent,
        ZxEventsComponent,
        ZxScteComponent,
        LoadingAnimationComponent,
        SpinnerAnimationComponent,
        SpinnerAnimationInlineComponent,
        PaginationComponent,
        ErrorComponent,
        SourceThumbnailComponent,
        SourceThumbnailFromServiceComponent,
        SourceThumbnailInfoComponent,
        SourceContentAnalysisComponent,
        EventsFilterFormComponent,
        SourceWebrtcVideoComponent,
        ZxListResizeComponent,
        ZxSplitterComponent,
        LocationSearchComponent,
        DiagramComponent,
        ReactFlowDiagramComponent,
        ZxBillingCodesCheckComponent,
        ActiveStatesComponent,
        ZxDeliveryChannelSourceSelectComponent,
        ZxMediaConnectVPCComponent,
        ZxMediaConnectMediaStreamComponent,
        ZxDynamicEventsComponent,
        ChannelDetailsComponent,
        ChannelAlertsComponent,
        BroadcasterDetailsComponent,
        ZecDetailsComponent,
        ZxDatePickerComponent,
        TableListComponent,
        ZxEditTableRowButtonsComponent,
        ZxChannelTargetsActionButtonsComponent,
        SourceSourcesComponent,
        TargetDetailsComponent,
        SourceDetailsComponent,
        MediaConnectSourceDetailsComponent,
        ZxIncidentSelectComponent,
        ZxStatusFullComponent,
        ZxTargetTargetsColumnComponent,
        ReportHistoryActionsColumnComponent,
        ZxNetworkObjectsStatusColumnComponent,
        FormFieldWarperComponent,
        ZxTargetChannelColumnComponent,
        IncidentsNameColumnComponent,
        RolesPermissionsColumnComponent,
        SourceSourcesNameColumnComponent,
        FeederInputNameColumnComponent,
        ZecInputNameColumnComponent,
        IoServerStatusColumnComponent,
        IoServerTunnelsColumnComponent,
        ReceiverOutputNameColumnComponent,
        ZecOutputNameColumnComponent,
        ZxSourceSourcesActionButtonsComponent,
        ChannelBitratesStatusColumnComponent,
        ChannelBitratesSourceStatusColumnComponent,
        ChannelBitratesProfileColumnComponent,
        ChannelBitratesSourceColumnComponent,
        IconColumnComponent,
        CollapsedFormSectionComponent,
        ZxNdiAudioSourcesComponent,
        SearchInputComponent,
        GenericNgbTitleComponent,
        MultiviewSourceSelectionComponent,
        MultiplexSourceSelectionComponent,
        FilterComponent,
        NotifyingMessageComponent,
        ObjectIncidentListComponent,
        SharedResourceListComponent,
        PasswordInputComponent,
        PasswordStrengthIconComponent,
        ZxDateTimePickerComponent,
        AdvancedSectionFormComponent,
        AnnouncementComponent,
        ZxIconComponent,
        ZxEventsTableRowActionsComponent,
        ZxEventsTypesFilterComponent,
        ZxSshKeysTableRowActionsComponent,
        ZxTableActionsComponent,
        ZxShowCopyLinksComponent,
        ZxBreadcrumbsComponent,
        SummarySourcesColComponent,
        GridObjectsColumnComponent,
        GridChannelColumnComponent,
        GridClusterColumnComponent,
        ChartContainerComponent,
        BitratesChartComponent,
        ZxUITourComponent,
        DialogObjectComponent,
        ActiveObjectsTargetBXColumnComponent,
        DetailsSectionComponent,
        DetailsPageHeaderComponent,
        LayoutButtonsComponent,
        PinButtonComponent,
        WidgetSectionComponent,
        NewDetailsComponent,
        WidgetSectionHeaderComponent,
        DetailsPageHeaderActionButtonsComponent,
        DetailsPageHeaderDropDownComponent,
        ZxObjectTrackingMainListComponent,
        ZxObjectTrackingSubListComponent,
        EditAlertingRuleComponent,
        ViewAlertingRuleComponent,
        FailoverRulesComponent,
        FailoverErrorConcealmentFormComponent,
        GenericDetailComponent,
        BroadcasterFieldSelectionComponent,
        TargetBroadcasterSelectionComponent,
        // Pipes
        FirstLetterPipe,
        StatusClassPipe,
        StatusTextPipe,
        TargetStatusTextPipe,
        StatusIconPipe,
        OrderByPipe,
        BitratePipe,
        SafeUrlPipe,
        DesnakePipe,
        ObjectTypeLetterPipe,
        BytesPipe,
        NamesPipe,
        BracketsPipe,
        VersionPipe,
        StatePipe,
        ErrorMessagePipe,
        UptimePipe,
        FilterPipe,
        TimestampPipe,
        FeederInputPipe,
        FeederInputTypePipe,
        FeederInputSourcePipe,
        FeederInputStatusPipe,
        BroadcasterInputPipe,
        BroadcasterInputPartialPipe,
        BroadcasterInputStatusPipe,
        BroadcasterOutputPipe,
        BroadcasterOutputErrorPipe,
        BroadcasterOutputStatusPipe,
        ReceiverOutputPipe,
        ReceiverOutputTypePipe,
        ReceiverOutputStatusPipe,
        ReceiverOutputTargetPipe,
        TimezonePipe,
        SingularPipe,
        MultiLevelFilter,
        NetworkPipe,
        DeescalatePipe,
        UIdPipe,
        ChromaMapperPipe,
        LocationDisplayNamePipe,
        MapPipe,
        ZecInputPipe,
        ZecInputStatusPipe,
        MinuteSecondsPipe
    ],
    providers: [
        FirstLetterPipe,
        StatusClassPipe,
        StatusTextPipe,
        TargetStatusTextPipe,
        StatusIconPipe,
        OrderByPipe,
        BitratePipe,
        SafeUrlPipe,
        DesnakePipe,
        ObjectTypeLetterPipe,
        BytesPipe,
        NamesPipe,
        BracketsPipe,
        VersionPipe,
        StatePipe,
        ErrorMessagePipe,
        UptimePipe,
        FilterPipe,
        TimestampPipe,
        FeederInputPipe,
        FeederInputTypePipe,
        FeederInputSourcePipe,
        FeederInputStatusPipe,
        BroadcasterInputPipe,
        BroadcasterInputPartialPipe,
        BroadcasterInputStatusPipe,
        BroadcasterOutputPipe,
        BroadcasterOutputErrorPipe,
        BroadcasterOutputStatusPipe,
        ReceiverOutputPipe,
        ReceiverOutputTypePipe,
        ReceiverOutputStatusPipe,
        ReceiverOutputTargetPipe,
        TimezonePipe,
        SingularPipe,
        MultiLevelFilter,
        NetworkPipe,
        DeescalatePipe,
        UIdPipe,
        ChromaMapperPipe,
        LocationDisplayNamePipe,
        MapPipe,
        ZecInputPipe,
        ZecInputStatusPipe,
        MinuteSecondsPipe
    ]
})
export class SharedModule {}
