<fieldset class="form-group">
    <legend>
        {{ label }}
        <fa-icon *ngIf="required" icon="asterisk" size="xs"></fa-icon>
        <!-- RestartContent -->
        <fa-icon *ngIf="isRestartRequiredTip" icon="redo" size="sm" [ngbTooltip]="RestartContent" triggers="hover click" [closeDelay]="500"></fa-icon>
        <ng-template #RestartContent>{{ "RESTART_REQUIRED" | translate }}</ng-template>
        <!-- Content -->
        <fa-icon *ngIf="lockTip" icon="lock" size="sm" [ngbTooltip]="Content" triggers="hover click" [closeDelay]="500"></fa-icon>
        <fa-icon *ngIf="circleTip" icon="info-circle" [ngbTooltip]="Content" triggers="hover click" [closeDelay]="500"></fa-icon>
        <ng-template #Content><span [innerHTML]="tooltip"></span> </ng-template>
    </legend>
    <mat-button-toggle-group name="import" aria-label="import" [formControl]="ngControl.control" (focus)="markAsTouched()">
        <mat-button-toggle *ngFor="let item of inputKeyAndValues | keyvalue" [value]="item.value">{{ item.key | translate }}</mat-button-toggle>
    </mat-button-toggle-group>
    <app-input-errors-indicator [errors]="ngControl.errors" [fieldName]="label" [invalid]="invalid"> </app-input-errors-indicator>
    <small *ngIf="note">{{ note | translate }}</small>
</fieldset>
