import { Component, OnInit, Input } from "@angular/core";
import { SomeZixiObject } from "src/app/models/shared";

import { StatusClassPipe } from "./../../../pipes/status-class.pipe";
import { StatusIconPipe } from "./../../../pipes/status-icon.pipe";

@Component({
    selector: "zx-status-icon",
    providers: [StatusClassPipe, StatusIconPipe],
    templateUrl: "./zx-status-icon.component.html"
})
export class ZxStatusIconComponent implements OnInit {
    @Input() model: Partial<SomeZixiObject>;
    @Input() size?: string;
    @Input() status?: string;
    @Input() standby?: boolean;
    @Input() icon?: string;
    @Input() disabled?: boolean;
    @Input() showTooltip = true;

    ngOnInit() {
        if (!this.model) return;
    }
}
