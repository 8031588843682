<!-- Default -->
<fa-icon
    *ngIf="model.generalStatus === 'success' || !model.activeStates || model.activeStates.length === 0"
    [icon]="disabled ? ('disabled' | statusIcon) : icon || (model | statusIcon)"
    class="status-{{ status || (model.generalStatus | statusClass) }}"
    size="{{ size }}"
    [ngClass]="{ disabled: standby || model.acknowledged }"
></fa-icon>
<!-- Standard w/ active state -->
<fa-icon
    *ngIf="model.generalStatus !== 'success' && model.activeStates && model.activeStates.length === 1"
    [icon]="disabled ? ('disabled' | statusIcon) : icon || (model | statusIcon)"
    class="status-{{ status || (model.generalStatus | statusClass) }}"
    size="{{ size }}"
    [ngClass]="{ disabled: standby || model.acknowledged }"
    [ngbTooltip]="showTooltip ? activeStatesContent : ''"
    triggers="hover click"
    container="body"
></fa-icon>
<!-- Count -->
<fa-layers
    *ngIf="model.generalStatus !== 'success' && model.activeStates && model.activeStates.length > 1"
    [ngbTooltip]="showTooltip ? activeStatesContent : ''"
    triggers="hover click"
    container="body"
>
    <fa-icon
        [icon]="'circle'"
        class="status-{{ status || (model.generalStatus | statusClass) }}"
        size="{{ size }}"
        [ngClass]="{ disabled: standby || model.acknowledged }"
    ></fa-icon>
    <fa-layers-text transform="shrink-6" content="{{ model.activeStates.length }}" style="color: var(--white); font-weight: 700"></fa-layers-text>
</fa-layers>
<!-- Tooltip -->
<ng-template #activeStatesContent>
    <div *ngIf="!model.activeStates || model.activeStates.length < 1">
        {{ model | statusText | translate }}
    </div>
    <div *ngIf="model.activeStates && model.activeStates.length > 0">
        <ul class="status-list-style">
            <li *ngFor="let state of model.activeStates">{{ state.type | uppercase | translate }} - {{ state.message }}</li>
        </ul>
    </div>
</ng-template>
