import { Component, Input, HostListener } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import * as _ from "lodash";
import moment from "moment";

import { ModalService } from "./../../shared/modals/modal.service";
import { Constants } from "./../../../constants/constants";
import { ZixiObject } from "./../../../models/shared";
import { MixpanelService } from "src/app/services/mixpanel.service";

class MuteAction {
    acknowledged?: number | boolean;
    muted?: boolean;

    muted_until?: string;
    flapping?: string;
}

@Component({
    selector: "zx-mute",
    templateUrl: "./zx-mute.component.html"
})
export class ZxMuteComponent {
    private model$: ZixiObject;
    @Input() muteStateChangedCallback?: () => void;
    @Input() set model(value: ZixiObject) {
        this.model$ = value;
    }
    get model(): ZixiObject {
        return this.model$;
    }

    @Input() type: string;
    @Input() mobile?: boolean;
    working = false;
    showPicker = false;
    muteUntilVal: Date;
    muteUntilString: string;

    @HostListener("click", ["$event"])
    private clickInside(event: MouseEvent) {
        event.stopPropagation();
    }

    @HostListener("document:click")
    private clickOutside() {
        this.showPicker = false;
        this.muteUntilString = null;
    }

    constructor(
        private http: HttpClient,
        private modalService: ModalService,
        private mixpanelService: MixpanelService
    ) {}

    toggleMute() {
        this.mixpanelService.sendEvent((this.model.active_mute ? "unmute" : "mute") + " " + this.type);
        return this.putIt({
            muted: !this.model.active_mute,
            muted_until: null,
            flapping: null
        });
    }

    dateChanged(event: string) {
        if (event !== null) {
            this.muteUntilVal = new Date(event);
        }
    }

    muteUntil() {
        this.showPicker = true;
        this.muteUntilVal = null;
    }

    async muteUntilMobile() {
        const result = await this.modalService.singleDatepicker();
        if (result) {
            this.save(result);
        }
    }

    close() {
        this.showPicker = false;
        this.muteUntilString = null;
    }

    save(date: Date) {
        this.mixpanelService.sendEvent("mute " + this.type + " until: " + moment(date).toISOString());
        return this.putIt({
            muted: true,
            muted_until: moment(date).toISOString(),
            flapping: null
        });
    }

    putIt(params: MuteAction) {
        this.working = true;

        this.http
            .put([Constants.apiUrl + Constants.apiUrls[this.type] + "/" + this.model.id].join(""), params)
            .subscribe(
                () => {
                    _.extend(this.model, params);
                    if (typeof params.muted === "boolean") _.extend(this.model, { active_mute: params.muted });
                    this.showPicker = false;
                    this.muteUntilString = null;
                    this.working = false;
                    if (this.muteStateChangedCallback) {
                        this.muteStateChangedCallback();
                    }
                },
                // eslint-disable-next-line no-console
                error => console.log("Could not put", error)
            );
    }
}
