import { Component, Input } from "@angular/core";
import { ValidationErrors } from "@angular/forms";
import { ZxRegexPatternValidationError } from "../../utilities/zx-forms-validators";
/**
 * Implementation Notes:
 * Handle validation errors generated by ZxFormsValidators and display corresponding UI messages.
 * For more details, see implementation notes in validators.ts
 */

@Component({
    selector: "zx-forms-field-errors",
    templateUrl: "./zx-forms-field-errors.component.html",
    styleUrls: ["./zx-forms-field-errors.component.scss"]
})
export class ZxFormsFieldErrorsComponent {
    zxValidationErrors: ValidationErrors = null;
    zxValidationRegexPatternErrors = [] as ZxRegexPatternValidationError[];

    @Input()
    set errors(validationErrors: ValidationErrors) {
        this.zxValidationErrors = validationErrors;
        this.zxValidationRegexPatternErrors = validationErrors
            ? Object.values(validationErrors).filter(error => error?.zxValidationRegexPattern)
            : [];
    }
}
