import { Component, Input, OnInit, Self } from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { ZxFormsHelper } from "../../utilities/zx-forms-helper";
import { TooltipConfigs } from "../../utilities/zx-forms-tooltips-configs";

export type Item = {
    [key: string]: unknown;
};

@Component({
    selector: "zx-forms-select-single",
    templateUrl: "./zx-forms-select-single.component.html"
})
export class ZxFormsSelectSingleComponent implements OnInit, ControlValueAccessor {
    // Required Inputs
    @Input() getItems: (() => Item[]) | (() => Promise<Item[]>);
    @Input() isSubmitted: boolean;
    @Input() translateKey: { label: string; placeholder?: string };
    // Optional Inputs
    @Input() tooltipsConfigs: TooltipConfigs[] = [];
    @Input() bindValueToItemProperty = "id";
    @Input() bindLabelToItemProperty = "name";
    @Input() isClearable = true;
    @Input() getDefaultItem: (items: Item[]) => Item;
    // Variables
    items: Item[];
    inputId = ZxFormsHelper.generateId("single_select");
    defaultTooltipsConfigs: TooltipConfigs[] = [];
    isLoading = true;
    isDisabled: boolean;
    selectedValue: string | number = null;
    notifyControlOnChange: Function;
    notifyControlOnTouch: Function;

    constructor(@Self() public ngControl: NgControl) {
        this.ngControl.valueAccessor = this;
    }

    async ngOnInit(): Promise<void> {
        this.items = await this.getItems();
        if (!this.selectedValue && this.getDefaultItem) {
            this.setDefaultValue();
        }
        this.isLoading = false;
    }

    get isInvalid(): boolean {
        return (
            this.ngControl.control.invalid &&
            (this.ngControl.control.touched || this.ngControl.control.dirty || this.isSubmitted)
        );
    }

    get tooltipConfigsForRendering(): TooltipConfigs[] {
        return [...this.tooltipsConfigs, ...this.defaultTooltipsConfigs];
    }

    setDefaultValue() {
        const defaultItem = this.getDefaultItem(this.items);
        const defaultValue = defaultItem[this.bindValueToItemProperty] as string | number;
        this.selectedValue = defaultValue;
        this.notifyControlOnChange(defaultValue);
    }

    writeValue(selectedValue: string | number | null): void {
        this.selectedValue = selectedValue;
    }

    registerOnChange(fn: any): void {
        this.notifyControlOnChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.notifyControlOnTouch = fn;
    }
    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}
