<div class="title-bar">
    <div class="row justify-content-center">
        <div class="col-12 col-md-8 col-xxl-6">
            <div class="title">
                <h1>
                    <ng-container *ngIf="formAction === FORM_ACTION.CREATE">{{ "CREATE_NEW" | translate }} </ng-container>
                    <ng-container *ngIf="formAction === FORM_ACTION.EDIT">{{ "EDIT" | translate }} </ng-container>
                    <ng-container *ngIf="formAction === FORM_ACTION.CLONE">{{ "CLONE" | translate }} </ng-container>
                    <ng-container *ngFor="let titleTranslateKey of titleTranslateKeys">{{ titleTranslateKey | translate }} </ng-container>
                    <fa-icon
                        *ngIf="tour"
                        #tourTooltip="matTooltip"
                        (click)="onTourClick()"
                        matTooltip="{{ tour.tooltipTranslateKey | translate }}"
                        size="xs"
                        icon="question-circle"
                        class="ms-2 tourStartButton"
                        matTooltipPosition="below"
                        matTooltipClass="below"
                    ></fa-icon>
                </h1>
            </div>
        </div>
    </div>
</div>
