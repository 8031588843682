import { Component, Input, Self, inject } from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { ZxFormsHelper } from "../../utilities/zx-forms-helper";
import { TooltipConfigs } from "../../utilities/zx-forms-tooltips-configs";
import { SharedService } from "src/app/services/shared.service";
import { ClipboardService } from "ngx-clipboard";

@Component({
    selector: "zx-forms-input-password",
    templateUrl: "./zx-forms-input-password.component.html",
    styleUrls: ["./zx-forms-input-password.component.scss"]
})
export class ZxFormsInputPasswordComponent implements ControlValueAccessor {
    // Required Inputs
    @Input() translateKey: { label: string; placeholder?: string };
    @Input() isSubmitted: boolean;
    // Optional Inputs
    @Input() tooltipsConfigs: TooltipConfigs[] = [];
    @Input() hasGeneratePasswordButton = true;
    // Variables - Public
    value: string = null;
    inputId = ZxFormsHelper.generateId("password_input");
    isPasswordVisible = false;
    isDisabled: boolean;
    notifyControlOnChange: Function;
    notifyControlOnTouch: Function;
    // Services
    private sharedService = inject(SharedService);
    private clipboardService = inject(ClipboardService);

    constructor(@Self() public ngControl: NgControl) {
        this.ngControl.valueAccessor = this;
    }

    get isInvalid(): boolean {
        return (
            this.ngControl.control.invalid &&
            (this.ngControl.control.touched || this.ngControl.control.dirty || this.isSubmitted)
        );
    }

    generatePassword() {
        const password = this.sharedService.generateStrongPassword();
        this.value = password;
        this.notifyControlOnChange(password);
    }

    togglePasswordVisibility() {
        this.isPasswordVisible = !this.isPasswordVisible;
    }

    copyPassword() {
        this.clipboardService.copy(this.value);
    }

    writeValue(value: string | null): void {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.notifyControlOnChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.notifyControlOnTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}
