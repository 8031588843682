import { ComponentRef } from "@angular/core";
import { KeyMap, ZixiObject, ZixiPlus } from "src/app/models/shared";
import { GridClusterColumnComponent } from "./grid-cluster-column.component";

export function assignComponentsGridClusterAdapter(
    componentRef: ComponentRef<GridClusterColumnComponent>,
    row: KeyMap<ZixiPlus>,
    searchTerm: string[]
) {
    const componentInstance = componentRef.instance;
    componentInstance.object = row;
    componentInstance.searchTerm = searchTerm;
}
