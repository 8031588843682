import { ComponentRef } from "@angular/core";
import { KeyMap, ZixiObject, ZixiPlus } from "src/app/models/shared";
import { GridChannelColumnComponent } from "./grid-channel-column.component";

export function assignComponentsGridChannelAdapter(
    componentRef: ComponentRef<GridChannelColumnComponent>,
    row: KeyMap<ZixiPlus>,
    searchTerm: string[]
) {
    const componentInstance = componentRef.instance;
    componentInstance.object = row;
    componentInstance.searchTerm = searchTerm;
}
