<ng-select
    class="form-control"
    name="{{ name }}"
    [disabled]="disabled"
    [items]="mediaconnectFlows"
    [clearable]="clearable || false"
    [loading]="loading"
    [multiple]="true"
    [hideSelected]="true"
    [closeOnSelect]="false"
    [clearSearchOnAdd]="true"
    bindLabel="name"
    bindValue="id"
    placeholder="{{ 'SELECT' | translate }} {{ 'AWS_MEDIACONNECT_FLOW' | translate }}"
    (change)="modelChanged()"
    [(ngModel)]="model"
    required="{{ required || false }}"
>
    <ng-template ng-label-tmp let-item="item" let-clear="clear" *ngIf="!loading">
        <span *ngIf="item.name" class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
        <span *ngIf="item.name" class="ng-value-label"><zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name }} @ {{ item.region }}</span>
    </ng-template>
    <ng-template ng-label-tmp let-item="item" *ngIf="loading"> {{ "LOADING" | translate }}... </ng-template>
    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name }} @ {{ item.region }}
    </ng-template>
</ng-select>
